<template>
  <div style="">
    <v-dialog
        v-model="show"
        width="80%"
        style="font-size: 18px!important;"
    >
      <v-card style="background-color: #E5E5E5;">
        <v-card-title>
          编辑{{ doctor.nickname }}问诊时间
        </v-card-title>
        <v-card-text style="padding: 0 10px">
          <v-container fluid>
            <StadiumBox style="margin-top: 30px;font-size: 16px!important;">
              图文问诊费用
            </StadiumBox>

            <StadiumBox style="margin-top: 30px;">
              <v-container fluid>
                <v-row justify="center">
                  <v-subheader style="font-size: 16px!important;">图文问诊费用</v-subheader>
                  <div>
                    <v-text-field dense type="number" solo style="width: 100px"
                                  v-model="computeTextInquiry"></v-text-field>
                  </div>
                  <v-subheader>元/次</v-subheader>
                </v-row>
                <v-row justify="center">
                  <v-btn color="primary" @click="saveText" style="font-size: 16px!important;">确认</v-btn>
                </v-row>
              </v-container>
            </StadiumBox>
            <StadiumBox style="margin-top: 30px">
              <div style="display: flex;align-items: baseline;">
                <div style="font-size: 16px!important;">图文问诊时间</div>
                <div style="font-size: 0.5rem;margin-left: 20px;font-size: 16px!important;">
                  说明：用户可在以下指定时间发起问诊，医生在接诊的间隙回复即可。以下时间段外会显示图文问诊不可用。问诊金额设置成0元表示停用图文问诊
                </div>
              </div>
            </StadiumBox>
            <StadiumBox style="margin-top: 30px">
              <div style="width: 100%">
                <div style="display: flex;align-items: center">
                  <v-btn color="primary" @click="addTextTime">添加图文问诊时间</v-btn>

                  <h4 style="color: red;margin-left: 20px">{{ $offsite != 8 ? '*此处按北京时间展示':'' }}</h4>
                </div>
                <el-table
                    fit
                    :data="doctor.textInquires"
                    style="width: 100%;font-size: 1rem">
                  <el-table-column
                      prop="inquiryDate"
                      label="日期">
                    <template slot-scope="scope">
                      <span v-if="scope.row.dateType == 3">每天</span>
                      <span v-for="(item,index) in scope.row.inquiryDate"
                            :key="index">{{ dateCompute(scope.row.dateType, item) + '、' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="name"
                      label="时间">
                    <template slot-scope="scope">
                      <span>{{ scope.row.startTime }}~{{ scope.row.endTime }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                      width="100"
                      prop="address"
                      label="操作">
                    <template slot-scope="scope">
                      <a v-if="scope.row.status == 1" @click="modifyStatus(scope.row,2)"
                         style="color: rgba(125, 172, 232, 1);text-decoration: underline">禁用</a>
                      <a v-if="scope.row.status == 2" @click="modifyStatus(scope.row,1)"
                         style="color: rgba(125, 172, 232, 1);text-decoration: underline">启用</a>

                      <a @click="modifyStatus(scope.row,3)"
                         style="color: rgba(125, 172, 232, 1);margin-left: 10px;text-decoration: underline">删除</a>
                    </template>

                  </el-table-column>
                </el-table>
              </div>
            </StadiumBox>
            <StadiumBox style="margin-top: 30px;font-size: 16px!important;">
              视频问诊费用
            </StadiumBox>
            <StadiumBox style="margin-top: 30px;font-size: 16px!important;">
              <v-container fluid>
                <v-row justify="center">
                  <v-subheader style="font-size: 16px!important;">视频问诊费用</v-subheader>
                  <div>
                    <v-text-field dense type="number" solo style="width: 100px"
                                  v-model="computeVideoInquiry"></v-text-field>
                  </div>
                  <v-subheader>元/30分钟</v-subheader>
                </v-row>
                <v-row justify="center">
                  <v-subheader style="font-size: 16px!important;">用户取消预约手续费</v-subheader>
                  <div>
                    <v-text-field
                        maxlength="3"
                        onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" dense
                        solo style="width: 100px"
                        v-model="computeServiceCharge"></v-text-field>
                  </div>
                  <v-subheader>%</v-subheader>
                </v-row>
                <v-row justify="center">
                  <v-btn color="primary" @click="saveVideo">确认</v-btn>
                </v-row>
              </v-container>
            </StadiumBox>
            <StadiumBox style="margin-top: 30px">
              <div style="display: flex;align-items: baseline;">
                <div style="font-size: 16px!important;">添加视频问诊时间</div>
                <div style="font-size: 16px!important;margin-left: 20px">
                  说明：用户可在以下时间发起视频问诊预约，最长预约时间为14天后。问诊金额设置成0元表示停用视频问诊
                </div>
              </div>
            </StadiumBox>
            <StadiumBox style="margin-top: 30px">
              <div style="width: 100%">
                <div style="display: flex;align-items: center">
                  <v-btn color="primary" @click="addVideoTime">添加视频问诊时间</v-btn>
                  <h4 style="color: red;margin-left: 20px">{{ $offsite != 8 ? '*此处按北京时间展示' :''}}</h4>
                </div>
                <el-table
                    fit
                    :data="doctor.videoInquires"
                    style="width: 100%;font-size: 1rem">
                  <el-table-column
                      prop="inquiryDate"
                      label="日期">
                    <template slot-scope="scope">
                      <span v-if="scope.row.dateType == 3">每天</span>
                      <span v-for="(item,index) in scope.row.inquiryDate"
                            :key="index">{{ dateCompute(scope.row.dateType, item) + '、' }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="name"
                      label="时间">
                    <template slot-scope="scope">
                      <span>{{ scope.row.startTime }}~{{ scope.row.endTime }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                      width="100"
                      prop="address"
                      label="操作">
                    <template slot-scope="scope">
                      <a v-if="scope.row.status == 1" @click="modifyStatus(scope.row,2)"
                         style="color: rgba(125, 172, 232, 1);text-decoration: underline">禁用</a>
                      <a v-if="scope.row.status == 2" @click="modifyStatus(scope.row,1)"
                         style="color: rgba(125, 172, 232, 1);text-decoration: underline">启用</a>

                      <a @click="modifyStatus(scope.row,3)"
                         style="color: rgba(125, 172, 232, 1);margin-left: 10px;text-decoration: underline">删除</a>
                    </template>

                  </el-table-column>
                </el-table>
              </div>

            </StadiumBox>


          </v-container>


        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="show = false"
          >
            取消
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="confirm()"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DateTimeSelectDialog ref="textDateTime" @confirm="(date)=>textDateTimeConfirm(date)"></DateTimeSelectDialog>
    <DateTimeSelectDialog ref="videoDateTime" @confirm="(date)=>videoTimeConfirm(date)"></DateTimeSelectDialog>
  </div>
</template>

<script>
import StadiumBox from "../layout/StadiumBox";
import DateTimeSelectDialog from "./DateTimeSelectDialog";

export default {
  name: "DoctorEditDialog",
  components: {DateTimeSelectDialog, StadiumBox},
  props: {},
  computed: {
    computeTextInquiry: {
      get() {
        return this.doctor.textInquiry / 100
      },
      set(val) {
        this.doctor.textInquiry = val * 100
      }
    },
    computeVideoInquiry: {
      get() {
        return this.doctor.videoInquiry / 100
      },
      set(val) {
        this.doctor.videoInquiry = val * 100
      }
    },
    computeServiceCharge: {
      get() {
        return this.doctor.serviceCharge
      },
      set(val) {
        if (val >= 100) {
          this.doctor.serviceCharge = 100
        } else {
          this.doctor.serviceCharge = val
        }

        //   if (val >= 100) {
        //     this.doctor.serviceCharge = 100
        //
        //   } else {
        //     this.doctor.serviceCharge = val
        //
        //   }
        // }
      }

    },
  },
  watch: {
    show: {
      handler(newVal) {
        if (!newVal) {
          this.$emit("complete")
        }
      },
      deep: true,
    }
  },
  data() {
    return {
      show: false, doctorId: "", doctor: {},
      textInquiry: 0,
      videoInquiry: 0,
      serviceCharge: 0,
    }
  },
  methods: {
    showDialog(item) {
      this.doctorId = item.userId
      this.show = true
      this.fetchDoctorInfo()
    },
    confirm() {
      this.show = false
      this.$emit("confirm")
    },
    addTextTime() {
      this.$refs.textDateTime.open()
    },
    addVideoTime() {
      this.$refs.videoDateTime.open()
    },
    textDateTimeConfirm(data) {
      console.log(data)
      data.userId = this.doctor.userId
      data.doctorId = this.doctor.id
      data.inquiryType = 1
      this.$server.sys.modifyDoctorInquiry(data).then(res => {
        if (res.code == 0) {
          this.$message.success("图文问诊时间添加成功")
          this.fetchDoctorInfo()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    videoTimeConfirm(data) {
      data.userId = this.doctor.userId
      data.doctorId = this.doctor.id
      data.inquiryType = 2
      this.$server.sys.modifyDoctorInquiry(data).then(res => {
        if (res.code == 0) {
          this.$message.success("视频问诊时间添加成功")
          this.fetchDoctorInfo()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    fetchDoctorInfo() {
      this.$server.sys.getDoctorInfo(this.doctorId).then(res => {
        if (res.code == 0) {
          console.log(res.data)
          this.doctor = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    saveText() {
      let data = {
        textInquiry: this.doctor.textInquiry,
        userId: this.doctor.userId
      }
      this.$server.sys.modifyDoctorInfo(data).then(res => {
        if (res.code == 0) {
          this.$message.success("保存图文问诊费用成功")
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    saveVideo() {
      let data = {
        videoInquiry: this.doctor.videoInquiry,
        serviceCharge: this.doctor.serviceCharge,
        userId: this.doctor.userId
      }
      this.$server.sys.modifyDoctorInfo(data).then(res => {
        if (res.code == 0) {
          this.$message.success("保存视频问诊费用成功")
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    dateCompute(dateType, item) {
      switch (dateType) {
        case 1:
          return `每月${item + 1}日`
        case 2:
          switch (item + 1) {
            case 1:
              return `每周一`
            case 2:
              return `每周二`
            case 3:
              return `每周三`
            case 4:
              return `每周四`
            case 5:
              return `每周五`
            case 6:
              return `每周六`
            case 7:
              return `每周日`
          }
          break
        case 3:
          console.log(123123123123)
          return `每周日`
      }
    },
    modifyStatus(row, status) {
      let id = row.id
      this.$server.sys.modifyDoctorInquiryStatus(id, status).then(res => {
        if (res.code == 0) {
          if (status == 1) {
            this.$message.success("问诊时间已启用")
          } else if (status == 2) {
            this.$message.success("问诊时间已关闭")
          } else if (status == 3) {
            this.$message.success("问诊时间已删除")
          }
          this.fetchDoctorInfo()
        } else {
          this.$message.error(res.msg)
        }
      })
      console.log(row)
      console.log(status)
    }
  }
}
</script>

<style scoped>

</style>
