<template>
  <div>
    <v-dialog
        v-model="show"
        width="500"
    >

      <v-card>
        <v-card-title >
          {{content}}
        </v-card-title>
        <v-card-text>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="show = false"
          >
            取消
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="confirm()"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogComponents",
  props:{
    content:{
      type: String,
      default: "确定要删除吗？"
    }
  },
  data(){
    return {
      show:false
    }
  },
  methods:{
    showDialog(){
      this.show = true
    },
    confirm(){
      this.show = false
      this.$emit("confirm")
    }
  }
}
</script>

<style scoped>

</style>
