import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css' //
Vue.use(Vuetify);
import zhHans from 'vuetify/lib/locale/zh-Hans'

// const theme = {
//     primary: '#FF957A',
//     secondary: '#9C27b0',
//     accent: '#e91e63',
//     info: '#00CAE3',
//     success: '#4CAF50',
//     warning: '#FB8C00',
//     error: '#FF5252',
// }

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // 默认值 - 仅用于展示目的
    },
    lang: {
        locales: { zhHans },
        current: 'zhHans',
    },
    theme: {
        themes: {
            light: {
                primary: "#FF957A", // #E53935
            },
        },
    },
});
