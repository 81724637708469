<template>
  <div>
    <v-dialog
        v-model="show"
        width="70%"

    >


      <v-card style="padding: 20px 0">
        <v-card-title>
          完成病例
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <h3>主诉</h3>
              <v-text-field v-model="caseInfo.suit" solo placeholder="请输入主诉" style="margin-top: 10px"></v-text-field>
              <el-upload
                  :action="action"
                  :headers="headers"
                  list-type="picture-card"
                  :on-success="handleCardList1Success"
                  :file-list="cardImageList1"
                  :on-remove="handCardList1Remove">
                <i class="el-icon-plus"></i>
              </el-upload>
            </v-col>
            <v-col>
              <h3>检查</h3>
              <v-text-field v-model="caseInfo.inspection" solo placeholder="请输入检查"
                            style="margin-top: 10px"></v-text-field>
              <el-upload
                  :action="action"
                  :headers="headers"
                  list-type="picture-card"
                  :on-success="handleCardList2Success"
                  :file-list="cardImageList2"
                  :on-remove="handCardList2Remove">
                <i class="el-icon-plus"></i>
              </el-upload>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3>诊断</h3>
              <v-text-field v-model="caseInfo.diagnosis" solo placeholder="请输入诊断"
                            style="margin-top: 10px"></v-text-field>
            </v-col>
            <v-col>
              <h3>医嘱</h3>
              <v-text-field v-model="caseInfo.advice" solo placeholder="请输入医嘱" style="margin-top: 10px"></v-text-field>
              <el-upload
                  :action="action"
                  :headers="headers"
                  list-type="picture-card"
                  :on-success="handleCardList3Success"
                  :file-list="cardImageList3"
                  :on-remove="handCardList3Remove">
                <i class="el-icon-plus"></i>
              </el-upload>
            </v-col>
          </v-row>
        </v-card-text>

        <div style="display: flex;justify-content: center">
          <v-btn color="primary" @click="saveCase(false)">保存病例草稿</v-btn>
          <v-btn color="primary" @click="saveCase(true)" style="margin-left: 10px">保存病例并结束问诊</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {baseUrl} from "../../http/http";

export default {
  name: "SaveCaseDialog",
  props: {
    content: {
      type: String,
      default: "确定要删除吗？"
    }
  },
  data() {
    return {
      show: false,
      orderSn: '',
      baseUrl,
      caseInfo: {
        "advice": "",
        "adviceImages": [],
        "diagnosis": "",
        "inspection": "",
        "inspectionImages": [],
        "orderSn": "",
        "suit": "",
        "suitImages": []
      },
      cardImageList1: [],
      cardImageList2: [],
      cardImageList3: [],
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      },
      action: `${baseUrl}/file/media `
    }
  },
  methods: {
    showDialog(orderSn) {
      this.caseInfo = {
        "advice": "",
        "adviceImages": [],
        "diagnosis": "",
        "inspection": "",
        "inspectionImages": [],
        "orderSn": "",
        "suit": "",
        "suitImages": []
      }
      this.orderSn = orderSn
      this.show = true
      this.fetchCase()
    },
    confirm() {
      this.show = false
      this.$emit("confirm")
    },
    handleCardList1Success(res, file, fileList) {
      console.log(res)
      console.log(file)
      console.log(fileList)
      if (this.caseInfo.suitImages) {
        this.caseInfo.suitImages.push(res.data)
      } else {
        this.caseInfo.suitImages = []
        this.caseInfo.suitImages.push(res.data)
      }
    },
    handCardList1Remove(file, fileList) {
      let imageList = []
      fileList.forEach(x => {
        imageList.push(x.url)
      })
      this.caseInfo.suitImages = imageList
    },
    handleCardList2Success(res,) {
      if( this.caseInfo.inspectionImages){
        this.caseInfo.inspectionImages.push(res.data)
      } else {
        this.caseInfo.inspectionImages = []
        this.caseInfo.inspectionImages.push(res.data)
      }
    },
    handCardList2Remove(file, fileList) {
      let imageList = []
      fileList.forEach(x => {
        imageList.push(x.url)
      })
      this.caseInfo.inspectionImages = imageList
    },
    handleCardList3Success(res,) {
      if(this.caseInfo.adviceImages){
        this.caseInfo.adviceImages.push(res.data)
      } else {
        this.caseInfo.adviceImages = []
        this.caseInfo.adviceImages.push(res.data)
      }
    },
    handCardList3Remove(file, fileList) {
      let imageList = []
      fileList.forEach(x => {
        imageList.push(x.url)
      })
      this.caseInfo.adviceImages = imageList
    },
    fetchCase() {
      this.$server.order.fetchCase(this.orderSn).then(res => {
        this.caseInfo = res.data
        let arr1 = []
        let arr2 = []
        let arr3 = []

        res.data.suitImages && res.data.suitImages.forEach(item => {
          arr1.push({
            name: item,
            url: item
          })
        })
        res.data.inspectionImages && res.data.inspectionImages.forEach(item => {
          arr2.push({
            name: item,
            url: item
          })
        })
        res.data.adviceImages && res.data.adviceImages.forEach(item => {
          arr3.push({
            name: item,
            url: item
          })
        })
        this.cardImageList1 = arr1
        this.cardImageList2 = arr2
        this.cardImageList3 = arr3
        console.log(res)
      })
    },
    saveCase(isFinish) {
      if (!isFinish) {
        this.caseInfo.isFinish = isFinish
        this.caseInfo.orderSn = this.orderSn
        this.$server.order.saveCase(this.caseInfo).then(res => {
          if (res.code == 0) {
            this.$message({
              type: 'success',
              message: '病例填写成功!'
            });
            this.show = false
            this.caseInfo = {
              "advice": "",
              "adviceImages": [],
              "diagnosis": "",
              "inspection": "",
              "inspectionImages": [],
              "orderSn": this.orderSn,
              "suit": "",
              "suitImages": []
            }
            if (isFinish) {
              this.$message.success("问诊结束，聊天页面即将关闭")
              setTimeout(() => {
                this.$router.replace("/admin/diagnoseOnline/bookList").then(()=>{
                  this.$store.dispatch('rtc/zegoLogout').then(()=>{

                  })
                })
              }, 3000)
              setTimeout(() => location.reload(), 4000)
            }
          }
        })
      } else {
        this.$confirm('完成病例后将不能再修改，确认完成病历吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.caseInfo.isFinish = isFinish
          this.caseInfo.orderSn = this.orderSn
          this.$server.order.saveCase(this.caseInfo).then(res => {
            if (res.code == 0) {
              this.$message({
                type: 'success',
                message: '病例填写成功!'
              });
              this.show = false
              this.caseInfo = {
                "advice": "",
                "adviceImages": [],
                "diagnosis": "",
                "inspection": "",
                "inspectionImages": [],
                "orderSn": this.orderSn,
                "suit": "",
                "suitImages": []
              }
              if (isFinish) {
                this.$message.success("问诊结束，聊天页面即将关闭")
                setTimeout(() => {
                  this.$router.replace("/admin/diagnoseOnline/bookList").then(()=>{
                    this.$store.dispatch('rtc/zegoLogout').then(()=>{
                    })
                  })
                }, 3000)
                setTimeout(() => location.reload(), 4000)
              }
            }
          })
        }).catch(() => {

        });

      }

    },
  }
}
</script>

<style scoped>

</style>
