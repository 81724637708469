import {order as orderApi} from '../../http/api/order'

const state = {
    /**
     * 0 群组
     * 1 单聊
     * id 群组id/单聊对象id
     */
    inquiryOrderList: [],
    count: {},
    videoInquiryOrderList: [],
    currentOrder: {}
}
const mutations = {
    fetchOrderList(state,) {
        orderApi.fetchOrderInquiryList(1, 999, 2).then(res => {
            console.log(res)
            res.data.content.map(x => {
                x.count = 0
            })
            state.inquiryOrderList = res.data.content
        })
    },
    fetchVideoInquiryOrderList() {
        orderApi.fetchOrderInquiryList(1, 999, 1).then(res => {
            console.log(res)
            res.data.content.map(x => {
                x.count = 0
            })
            state.videoInquiryOrderList = res.data.content
        })
    },
    countPlus(state, groupId) {
        if (state.count[groupId]) {
            state.count[groupId] = state.count[groupId] += 1
        } else {
            state.count[groupId] = 1
        }

        // let index = state.inquiryOrderList.findIndex(x => x.groupId == groupId)
        // if(state.inquiryOrderList[index]){
        //     if (isNaN(state.inquiryOrderList[index].count)) {
        //         state.inquiryOrderList[index].count = 1
        //     } else {
        //         state.inquiryOrderList[index].count += 1
        //     }
        // }

    },
    resetCount(state, groupId) {
        // let index = state.inquiryOrderList.findIndex(x => x.groupId == groupId)
        // state.inquiryOrderList[index].count = 0

        if (state.count[groupId]) {
            state.count[groupId] = 0
        } else {
            state.count[groupId] = 0
        }
    },
    setCurrentOrder(state, order) {
        state.currentOrder = order
    }
}
const actions = {}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
