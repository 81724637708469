import { post,fetch } from "../http";

export const hospital = {
    // 登录
    hospitalList(currentPage,pageSize) {
        return post(`/hospital/records?currentPage=${currentPage}&pageSize=${pageSize}`,{
            "sortType": 2
        });
    },
    // 医院信息
    hospitalInfo() {
        return fetch(`/hospital`);
    },
    // 保存医院信息
    saveOrUpdateHospitalInfo(data){
        return post(`/hospital`,data);
    },
    // 获取所有医院列表
    fetchAllHospitalList(){
        return fetch(`/hospital/all`)
    }

}
