<template>
  <div>
    <v-dialog
        v-model="show"
        width="500"
        style="height: 80vh"
    >

      <v-card>
        <v-card-title >
          医院地址
        </v-card-title>
        <v-card-text>
          <iframe id="mapPage"  width="100%" style="height: 70vh" frameborder=0
                  src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=YV4BZ-HKR6P-HNSDH-L6HPX-XGVSJ-RWFGL&referer=宠小书小程序">
          </iframe>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="show = false"
          >
            取消
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="confirm()"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "MapDialog",
  props:{
    content:{
      type: String,
      default: "确定要删除吗？"
    }
  },
  data(){
    return {
      show:false,
      address:undefined
    }
  },
  mounted() {
    let _this = this
    window.addEventListener('message', function(event) {
      let loc = event.data;
      if (loc && loc.module === 'locationPicker') {
        console.log('location', loc);
        _this.address = loc
      }
    }, false);
  },
  methods:{
    showDialog(){
      this.show = true
    },
    confirm(){
      this.show = false
      this.$emit("confirm",this.address)
      this.address = undefined

    }
  }
}
</script>

<style scoped>

</style>
