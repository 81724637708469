<template>
  <div>
    <v-dialog
        v-model="show"
        width="400"
    >

      <v-card>
        <v-card-title>
          <iframe :src="src" width="375" height="720"></iframe>
        </v-card-title>
        <v-card-text>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="show = false"
          >
            关闭
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "IframeDialog",
  props: {
    content: {
      type: String,
      default: "确定要删除吗？"
    }
  },
  data() {
    return {
      show: false,
      src: ""
    }
  },
  methods: {
    showDialog(src) {
      console.log(src)
      this.src =' https://html5.yuhoulianghao.com:439' + src
      this.show = true
    },
    confirm() {
      this.show = false
      this.$emit("confirm")
    }
  }
}
</script>

<style scoped>

</style>
