<template>
  <div>
    <v-dialog
        v-model="show"
        width="450"
    >

      <v-card>
        <v-card-title>
          {{ content }}
        </v-card-title>
        <v-form style="padding: 10px 50px 10px  50px" lazy-validation ref="form">
          <v-text-field label="医院名称" placeholder="请输入医院名称" v-model="formData.name" :rules="nameRules"></v-text-field>
          <v-text-field label="用户名" placeholder="请输入用户名" v-model="formData.username"
                        :rules="usernameRules"></v-text-field>
          <v-text-field type="password" label="密码" placeholder="请输入密码" v-model="formData.password" :rules="password"></v-text-field>
          <v-text-field type="password" label="再次输入密码" placeholder="请再次输入密码" v-model="formData.passwordRep"
                        :rules="repPassword"></v-text-field>
        </v-form>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="closeDialog"
          >
            取消
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="confirm()"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AddHospitalDialog",
  props: {
    content: {
      type: String,
      default: "确定要删除吗？"
    }
  },
  data() {
    return {
      show: false,
      formData: {
        name: "",
        username: "",
        password: "",
        passwordRep: "",
      },
      nameRules: [
        v => !!v || '请输入医院名称',
      ],
      usernameRules: [
        v => !!v || '请输入用户名',
      ],
      password: [
        v => !!v || '请输入密码',
      ],
      repPassword: [
        v => !!v || '请再次输入密码',
        v => !(v !== this.formData.password) || '两次密码输入不一致',
      ]
    }
  },
  methods: {
    showDialog() {
      this.show = true
    },
    isEmptyStr(s) {
      return s == null || s === '';
    },
    confirm() {
      if (this.$refs.form.validate()) {
        this.$server.admin.addHospital(this.formData).then(res => {
          this.formData.username = ""
          this.formData.name = ""
          this.formData.password = ""
          this.formData.passwordRep = ""
          this.show = false
          console.log(res)
          this.$emit("confirm")
          this.$message.success("医院添加成功")
          this.formData = {
            name: "",
            username: "",
            password: "",
            passwordRep: "",
          }
        })
      }
    },
    closeDialog(){
      this.show = false
      this.formData = {
        name: "",
        username: "",
        password: "",
        passwordRep: "",
      }
    }
  }
}
</script>

<style scoped>

</style>
