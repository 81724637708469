import axios from 'axios';
// import baseUrl from "@/http/baseUrl";
import router from "@/router";
export const baseUrl = 'https://api.yuhoulianghao.com:8090'
// export const baseUrl = 'http://121.5.157.193:8090'
axios.defaults.timeout = 5000;
axios.defaults.baseURL = baseUrl;
axios.defaults.adapter = require('axios/lib/adapters/http');
import { Message } from 'element-ui';
//http request 拦截器
axios.interceptors.request.use(
    config => {
        let accessToken = sessionStorage.getItem("access_token");
        if (config.url === 'http://192.168.31.121:26363/third/resource/media') {
            config.headers = {
                'Authorization':'Bearer '+ accessToken,
                'Content-Type':'multipart/form-data',
                'TENANT-ID': '1'
            }
        } else if (accessToken) {
            config.headers = {
                'Authorization':'Bearer '+ accessToken,
                'Content-Type': 'application/json',
                'TENANT-ID': '1'
            }
            config.data = JSON.stringify(config.data);
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
axios.defaults.timeout = 300000;
//响应拦截器即异常处理
axios.interceptors.response.use(response => {
    console.log(response)
    if(response.data.code == 401){
        sessionStorage.removeItem("access_token");
        router.push({path: '/Login'})
    }
    if(response.data.code !== 0 ){
        Message.error(response.data.msg)
    }
    return response
}, err => {
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                console.log('错误请求')
                break;
            case 401:
                sessionStorage.removeItem("access_token");
                router.push({path: '/Login'})
                break;
            case 403:
                console.log('拒绝访问')
                break;
            case 404:
                console.log('请求错误,未找到该资源')
                break;
            case 405:
                console.log('请求方法未允许')
                break;
            case 408:
                console.log('请求超时')
                break;
            case 426:
                console.log("用户名或密码错误")
                break;
            case 500:
                console.log('服务器端出错')
                break;
            case 501:
                console.log('网络未实现')
                break;
            case 502:
                console.log('网络错误')
                break;
            case 503:
                console.log('服务不可用')
                break;
            case 504:
                console.log('网络超时')
                break;
            case 505:
                console.log('http版本不支持该请求')
                break;
            default:
                console.log(`连接错误${err.response.status}`)
        }
    } else {
        console.log('连接到服务器失败')
    }
    return Promise.resolve(err.response)
})


/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */

export function fetch(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @param params
 * @returns {Promise}
 */
export function post(url, data = {}, params = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, data, {
            params
        })
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}


/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function remove(url) {
    return new Promise((resolve, reject) => {
        axios.delete(url)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}
