<template>
  <PageBody title="商店库存">
    <div style="width: 100%">
      <v-btn color="primary" @click="addGoods">添加商品</v-btn>
      &nbsp;
      <v-btn color="primary" @click="importGoods">导入商品</v-btn>
      <el-table
          :data="tableData"
          style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="props">
            <div>
              <h3>图片</h3>
              <span v-if=" !props.row.images">暂无</span>
              <img v-for="(item,index) in props.row.images" :src="item" :key="index"
                   style="width: 180px;height: 180px;margin-right: 10px"/>
              <!--                {{ props.row }}-->
              <h3>说明</h3>
              <span>{{ props.row.intro }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-columnv xs
            prop="code"
            label="sku编号">
        </el-table-columnv>
        <el-table-column
            prop="categoryId"
            label="商品分类">
          <template slot-scope="scope">
            <span>{{ categoryConv(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="名称">
        </el-table-column>
        <el-table-column
            prop="specification"
            label="商品规格">
        </el-table-column>
        <el-table-column
            prop="goodsUnit"
            label="销售单位">
        </el-table-column>
        <el-table-column
            prop="ingredient"
            label="药物成分">
        </el-table-column>
        <el-table-column
            prop="formulation"
            label="药物剂型">
        </el-table-column>
        <el-table-column
            prop="brand"
            label="品牌">
        </el-table-column>
        <!--        <el-table-column-->
        <!--            prop="intro"-->
        <!--            label="说明">-->
        <!--        </el-table-column>-->
        <el-table-column
            prop="price"
            label="单价">
          <template slot-scope="scope">
            <span>{{ scope.row.price / 100 }}元</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="quantity"
            label="库存">
        </el-table-column>
        <!--        <el-table-column-->
        <!--            prop="images"-->
        <!--            label="图片">-->
        <!--        </el-table-column>-->
        <el-table-column
            prop="address"
            label="操作">
          <template slot-scope="scope">
            <a style="color: rgba(125, 172, 232, 1);text-decoration: underline" @click="editGoods(scope.row)">编辑</a>
            <a @click="modifyGoodsStatus(scope.row,2)" v-if="scope.row.status == 1"
               style="color: rgba(125, 172, 232, 1);margin-left: 10px;text-decoration: underline">下架</a>
            <a @click="modifyGoodsStatus(scope.row,1)" v-if="scope.row.status == 2"
               style="color: rgba(125, 172, 232, 1);margin-left: 10px;text-decoration: underline">上架</a>
<!--            <a @click="modifyGoodsStatus(scope.row,3)"-->
<!--               style="color: rgba(125, 172, 232, 1);margin-left: 10px;text-decoration: underline">删除</a>-->
          </template>
        </el-table-column>

      </el-table>
      <v-pagination
          v-model="page.currentPage"
          :length="page.totalPage"
          :total-visible="20"
          circle
          @input="onPageChange"
      >
      </v-pagination>
    </div>
    <AddGoodsDialog ref="addGoodsDialog" @confirm="(data)=>goodsConfirm(data)"></AddGoodsDialog>
    <ImportGoodsDialog ref="importGoodsDialog" @confirm="(data)=>importConfirm(data)" ></ImportGoodsDialog>
  </PageBody>
</template>

<script>
import PageBody from "../components/layout/PageBody";
import AddGoodsDialog from "../components/dialog/AddGoodsDialog";
import ImportGoodsDialog  from "@/components/dialog/ImportGoodsDialog";

export default {
  name: "GoodsList",
  components: {AddGoodsDialog, PageBody, ImportGoodsDialog},

  data() {
    return {
      tableData: [],
      page: {
        currentPage: 1,
        pageSize: 20,
      }
    }
  },
  mounted() {
    this.fetchGoodList()
  },
  methods: {
    onPageChange(event) {
      this.page.currentPage = event

      this.fetchGoodList()
    },
    addGoods() {
      this.$refs.addGoodsDialog.showDialog('add')
    },
    importGoods() {
      this.$refs.importGoodsDialog.showDialog('import')
    },
    goodsConfirm(data) {
      data.weight = Number(data.weight)
      this.$server.goods.addGoods(data).then(res => {
        if (res.code == 0) {
          this.$message.success("商品添加成功")
          this.fetchGoodList()
        } else {
          this.$message.error(res.msg)
        }
      })
      console.log(data)
    },
    importConfirm(data){
      data.forEach((dt)=>{dt.weight = Number(dt.weight)
        this.$server.goods.addGoods(dt).then(res => {
          if (res.code == 0) {
            this.$message.success("商品添加成功")
            this.fetchGoodList()
          } else {
            this.$message.error(res.msg)
            console.error(JSON.stringify(dt))
          }
        })
        console.log(dt)})
    },
    fetchGoodList() {
      let hospitalId = JSON.parse(sessionStorage.getItem('userInfo')).hospitalId
      let data = {
        hospitalId
      }
      this.$server.goods.fetchGoods(this.page.currentPage, this.page.pageSize, data).then(res => {
        this.tableData = res.data.content
        this.page.totalPage = res.data.totalPages
        console.log(res)
      })
    },
    editGoods(row) {
      this.$refs.addGoodsDialog.showDialog('edit', row)
      console.log(row)
    },
    modifyGoodsStatus(row, status) {
      console.log(row)
      console.log(status)
      this.$server.goods.modifyGoodsStatus(row.id, status).then(res => {
        if (res.code == 0) {
          if (status == 1) {
            this.$message.success("商品上架成功")
          } else if (status == 2) {
            this.$message.success("商品下架成功")
          } else if (status == 3) {
            this.$message.success("商品删除成功")
          }
          this.fetchGoodList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    categoryConv(row) {
      console.log(row)
      switch (row.categoryId) {
        case 1:
          return '处方药';
        case 2:
          return '非处方药';
        case 3:
          return '保健品';
        case 4:
          return '用品';
        case 5:
          return '食品';

      }
    }
  }
}
</script>

<style scoped>

</style>
