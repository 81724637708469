import {fetch} from "../http";

export const noauth = {
    // 获取字典类型
    fetchDict(type) {
        return fetch(`/noauth/common/medicine/types/${type}`);
    },
    // 获取验证码
    fetchCode(){
        return fetch(`/noauth/common`)
    },
    fetchLogistics(){
        return fetch(`/noauth/common/logistics`)
    }
}
