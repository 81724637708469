<template>


  <div style="height: 70vh;width: 100%">
    <StadiumBox>
      <div style="width: 100%">
        <div style="width: 100%;padding-right: 30px">
          <div style="display: flex;justify-content: space-between;width: 100%;align-items: center">
            <div>
              <div>
                <h3>用户名：{{ username }}</h3>
              </div>
              <div v-if="chatType == 1" style="display: flex;align-items: center">
                <h3>宠物名：{{ petDetail.name }}</h3>
                <h3 style="margin-left: 40px">宠物品种：{{ petDetail.typeName }} ({{
                    petDetail.categoryName
                  }})</h3>
                <h3 style="margin-left: 40px" @click="age">{{
                    petDetail.birthday ? age(petDetail.birthday) : '未知'
                  }}</h3>
              </div>
              <div v-if="chatType == 1">
                <h3>备注：{{ order.remark }}</h3>
              </div>
              <!--              <div v-if="chatType == 1" style="margin: 0 auto;margin-bottom: 10px"> {{-->
              <!--                  order.isClose === 1 ? '此次视频问诊时长已用完，您可与用户继续使用图文聊天功能直到您手动结束此病例。' : ''-->
              <!--                }}-->
              <!--              </div>-->
              <H3 v-if="chatType == 1" style="margin: 0 auto;margin-bottom: 10px;color: red"> {{
                  order.isClose === 1 ? '*此次视频问诊时长已用完，您可与用户继续使用图文聊天功能直到您手动结束此病例。' : ''
                }}
              </H3>
            </div>
            <div v-if="chatType == 1">
              <v-btn color="primary" style="width: 96px" @click="saveCase">结束问诊</v-btn>
            </div>
          </div>
        </div>
      </div>
    </StadiumBox>

    <StadiumBox style="height: 100%;margin-top: 20px;width: 100%">
      <div style="width: 100%;box-sizing: border-box">
        <div style="width: 100%;overflow-y: scroll;height: 90%" id="chatBox">
          <div style="display: flex;flex-direction: column;width: 100%">
            <!--            <div style="margin: 0 auto"> {{-->
            <!--                msgList.length > 0 ? $moment(msgList[0].info.time).format('YYYY年M月D日') : ''-->
            <!--              }}-->
            <!--            </div>-->
            <div v-for="(item,index) in  msgList" style="display: flex;flex-direction: column;width: 100%" :key="index">
              <div v-if="index == 0" style="margin: 0 auto">
                {{ index == 0 ? $moment(item.info.time).format('YYYY年M月D日') : '' }}
              </div>
              <div v-else style="margin: 0 auto">{{
                  $moment(msgList[index - 1].info.time).format('YYYY年M月D日') != $moment(item.info.time).format('YYYY年M月D日') ? $moment(item.info.time).format('YYYY年M月D日') : ''
                }}
              </div>

              <div :class="item.from === code? 'chat-right':'  chat-left'" :key="index">

                <div v-if="item.type == 'text' " style="display: flex;align-items: flex-start;">
                  <div style="flex-wrap: wrap;word-break: break-all">
                    {{ item.info.text }}
                  </div>
                  <div style="color: #999999;margin-left: 10px">
                    {{ $moment($localDateTime(item.info.time)).format('HH:mm') }}
                  </div>
                </div>


                <div v-if="item.type == 'image'">
                  <v-img :src="item.info.text" max-width="300px"/>
                  <div> {{ $moment($localDateTime(item.info.time)).format('HH:mm') }}</div>
                </div>

                <div v-if="item.type == 'note'" style="display: flex;align-items: flex-start;">
                  <a @click="tapNote(item)"> {{ item.info.text }}</a>
                  <div style="color: #999999;margin-left: 10px">
                    {{ $moment($localDateTime(item.info.time)).format('HH:mm') }}
                  </div>
                </div>

              </div>
            </div>


          </div>

        </div>
        <div style="height: 10%">
          <v-row style="" align="center">
            <v-col cols="1">
              <v-row justify="space-around">
                <!--                <div @click="sendTextMessage">-->
                <!--                  <v-img max-width="40px" max-height="40px" src="../assets/chat-bottom-bar-icon/image.png"/>-->
                <!--                </div>-->
                <div>
                  <el-upload
                      :action="action"
                      :headers="headers"
                      :show-file-list="false"
                      :on-success="handleImageSuccess">
                    <v-img max-width="40px" max-height="40px" src="../assets/chat-bottom-bar-icon/image.png"/>
                  </el-upload>
                </div>
              </v-row>

            </v-col>

            <v-col>
              <div class="input-box">
                <input v-model="chatContent" @keyup.enter="sendTextMessage"/>
                <!--                <v-img v-if="order.inquiryType == 2" @click="phoneCall" max-width="40px" max-height="40px"-->
                <!--                       style="margin-left: 30px"-->
                <!--                       src="../assets/chat-bottom-bar-icon/视频电话.png"/>-->
                <v-img @click="sendTextMessage" max-width="40px" max-height="40px" style="margin-left: 30px"
                       src="../assets/chat-bottom-bar-icon/发送.png"/>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </StadiumBox>
    <SaveCaseDialog ref="saveCaseDialog"></SaveCaseDialog>
    <IframeDialog ref="frameDialog"></IframeDialog>
  </div>
</template>

<script>
import StadiumBox from "../components/layout/StadiumBox";
import {baseUrl} from "../http/http";
import SaveCaseDialog from "../components/dialog/SaveCaseDialog";
import IframeDialog from "../components/dialog/IframeDialog";
import moment from "moment";
// import RecordDialog from "../components/dialog/RecordDialog";

export default {
  name: "DiagnoseOnline",
  components: {IframeDialog, SaveCaseDialog, StadiumBox},
  watch: {
    '$route'(to, from) {
      console.log(to)
      console.log(from)
      this.$store.state.rtc.miniDialog = true
      this.chatType = this.$route.query.type
      switch (this.$route.query.type) {
        case '1':
          console.log("图文问诊")
          this.chatData = {
            sn: this.$route.query.sn,
            type: this.$route.query.type,
            orderItemSn: this.$route.query.orderItemSn
          }
          this.fetchOrderDetail()
          break
        case '2':
          this.fetchPetDetail(this.$route.query.petId)
          this.username = this.$route.query.username
          this.userImId = this.$route.query.id
          this.fetchReceptionHistoryMsg()
          console.log("前台咨询")

          break
      }
    },
    msgList(oldValue,newValue){
      console.log(oldValue,newValue)
      setTimeout(() => {
        this.$nextTick(() => {
          let scrollDom = document.getElementById('chatBox');
          scrollDom.scrollTop = scrollDom.scrollHeight
        })
      }, 600)
    }
  },
  computed: {
    msgList() {
      if (this.chatType == 1) {
        return this.$store.state.conversion.conversion.find(x => x.id == this.chatData.orderItemSn) ? this.$store.state.conversion.conversion.find(x => x.id == this.chatData.orderItemSn).chat : []
      } else {
        console.log(this.$store.state.conversion.conversion.find(x => x.id == this.userImId) ? this.$store.state.conversion.conversion.find(x => x.id == this.userImId).chat : [])
        return this.$store.state.conversion.conversion.find(x => x.id == this.userImId) ? this.$store.state.conversion.conversion.find(x => x.id == this.userImId).chat: []
      }
    }
  },
  data() {
    let code = JSON.parse(sessionStorage.getItem("userInfo")).code
    return {
      baseUrl,
      chatContent: "",
      chatData: {
        sn: "",
        type: "",
        orderItemSn: ""
      },
      order: "",
      page: {
        currentPage: 1,
        pageSize: 500
      },
      code: code,
      petDetail: {},
      chatType: "",
      username: "",
      userImId: "",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      },
      action: `${baseUrl}/file/media `
    }
  },
  mounted() {
    this.$store.state.rtc.miniDialog = true
    this.chatType = this.$route.query.type
    switch (this.$route.query.type) {
      case '1':
        console.log("图文问诊/视频问诊")
        this.chatData = {
          sn: this.$route.query.sn,
          type: this.$route.query.type,
          orderItemSn: this.$route.query.orderItemSn
        }
        this.fetchOrderDetail()
        break
      case '2':
        this.fetchPetDetail(this.$route.query.petId)
        this.username = this.$route.query.username
        this.userImId = this.$route.query.id
        console.log("前台咨询")
        this.fetchReceptionHistoryMsg()
        break
    }


  },
  methods: {
    compare() {
      return function (a, b) {
        let value1 = moment(a['info']['time']).unix();
        let value2 = moment( b['info']['time']).unix();
        return value1 - value2;
      }
    },
    sendTextMessage() {
      if (this.chatContent == '') {
        return
      }
      let obj = {
        content: this.chatContent,
        type: "text",
        sn: this.chatData.sn
      }


      if (this.chatType == 1) {
        obj.to = this.chatData.orderItemSn
        obj.sendChatType = 'groupChat'
        obj.nickname = JSON.parse(sessionStorage.getItem('userInfo')).username
        if (JSON.parse(sessionStorage.getItem('userInfo')).avatar) {
          obj.avatar = JSON.parse(sessionStorage.getItem('userInfo')).avatar
        }
        // 视频 图文问诊
        obj.mode = 0
        if (this.order.inquiryType == 2) {
          obj.isVideo = true
        } else {
          obj.isVideo = false
        }
      } else if (this.chatType == 2) {

        obj.to = this.userImId
        obj.sendChatType = 'singleChat'
        obj.hospitalName = JSON.parse(sessionStorage.getItem('userInfo')).hospitalName
        obj.hospital = JSON.parse(sessionStorage.getItem('userInfo')).hospitalId
        obj.nickname = JSON.parse(sessionStorage.getItem('userInfo')).username
        if (JSON.parse(sessionStorage.getItem('userInfo')).avatar) {
          obj.avatar = JSON.parse(sessionStorage.getItem('userInfo')).avatar
        }
        // 前台问诊
        obj.mode = 1
      }

      this.$store.commit("im/sendTextMessage", obj)

      this.chatContent = ""
      setTimeout(() => {
        this.$nextTick(() => {
          let scrollDom = document.getElementById('chatBox');
          scrollDom.scrollTop = scrollDom.scrollHeight
        })
      }, 600)
    },
    sendImageMessage(url) {
      let obj
      if (this.chatType == 1) {
        obj = {
          to: this.chatData.orderItemSn,
          sendChatType: 'groupChat',
          mode: 0,
          content: url,
          type: 'image'
        }
        if (this.order.inquiryType == 2) {
          obj.isVideo = true
        } else {
          obj.isVideo = false
        }
      } else if (this.chatType == 2) {
        obj = {
          to: this.userImId,
          sendChatType: 'singleChat',
          mode: 1,
          content: url,
          type: 'image'
        }
      }

      this.$store.commit("im/sendTextMessage", obj)

      this.chatContent = ""
      setTimeout(() => {
        this.$nextTick(() => {
          let scrollDom = document.getElementById('chatBox');
          scrollDom.scrollTop = scrollDom.scrollHeight
        })
      }, 600)

    },
    fetchOrderDetail() {
      this.$server.order.fetchOrderDetail(this.chatData.sn).then(res => {
        this.order = res.data
        this.username = this.order.user.nickname
        this.petDetail = this.order.pet
        this.fetchHistoryMessage()
        if (this.order.inquiryType == 2) {
          console.log('视频问诊')
          if (this.order.isClose === 0) {
            this.$store.state.rtc.isCalling = true
            this.$store.state.rtc.currentRoomId = this.chatData.sn
          }

        }
        this.$store.commit("order/setCurrentOrder", this.order)
      })
    },
    fetchPetDetail(petId) {
      if(petId){
        this.$server.pet.fetchPetDetail(petId).then(res => {
          this.petDetail = res.data
        })
      }

    },
    fetchHistoryMessage() {
      this.$server.message.fetchHistoryMessage(this.page.currentPage, this.page.pageSize, this.chatData.orderItemSn).then(res => {
        // res.data.content.reverse();
        res.data.content.map(x => {
          let msg = JSON.parse(x.msg)
          msg.msgId = x.msgId
          msg.fromUserInfo = {
            nickname: '',
            avatar: ''
          }
          let msgCacheObj = {
            type: 0,
            id: msg.to,
            chat: [msg]
          }
          this.$store.commit('conversion/saveHistory', msgCacheObj)
        })
      })
    },
    fetchReceptionHistoryMsg() {
      let user = JSON.parse(sessionStorage.getItem('userInfo'))
      this.$server.message.fetchPersonalChatHistory(this.page.currentPage, this.page.pageSize, user.code, user.hospitalId, this.userImId).then(res => {
        res.data.content.forEach(x => {
          let msg = JSON.parse(x.msg)
          msg.msgId = x.msgId
          msg.fromUserInfo = {
            nickname: '',
            avatar: ''
          }
          let msgCacheObj = {
            type: 1,
            fromUser: x.fromUser,
            toUser: x.toUser,
            chat: [msg]
          }
          this.$store.commit('conversion/saveMessage', msgCacheObj)
        })
      })
    },
    handleImageSuccess(file) {
      this.sendImageMessage(file.data)
    },
    saveCase() {
      this.$refs.saveCaseDialog.showDialog(this.chatData.sn)
    },
    tapNote(item) {
      let url
      if (item.info.category == 114) {
        url = '/health/inspectionResault/inspectionResault'

      } else {
        url = '/pages/noteDetail/noteDetail'
      }
      let id = item.info.noteId

      let src = `${url}?id=${id}&isWeb=true&token=${sessionStorage.getItem('access_token')}`
      console.log(src)
      this.$refs.frameDialog.showDialog(src)
      console.log(item)
    },
    age(birthday) {
      let age = ''
      let day = moment().diff(birthday, 'day')
      let year = Math.floor(day / 365)
      let y = day % 365
      let month = Math.floor(y / 30)
      let d = Math.floor(day % 365 % 30)
      age += year + '岁' + month + '月' + d + '天'
      return age
    },
    phoneCall() {
      let obj = {
        to: this.chatData.orderItemSn,
      }
      this.$store.commit("im/sendTextMessage", obj)
    },
  }
}
</script>

<style scoped>

.bottom-bar {
  height: 80px;
  width: 100%;
  display: flex;
}

.chat-bar {
  height: 100%;
  border-radius: 16px;
  background: rgba(251, 240, 237, 1);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

input {
  /*background: rgba(251, 240, 237, 1);*/
  /*border-radius: 16px;*/
  height: 100%;
  width: 100%;
  font-size: 24px;
  padding: 0 20px;
  border: none;
}

.chat-left {
  margin-top: 10px;
  margin-left: 10px;
  padding: 16px;
  background: rgba(248, 248, 247, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 45%;
  min-width: 60px;
  margin-bottom: 20px;
  width: fit-content;
  word-wrap: break-word;
  box-sizing: border-box;
}

.chat-right {
  margin-top: 10px;
  margin-right: 10px;
  box-sizing: border-box;
  white-space: normal;
  padding: 16px;
  background: rgba(251, 240, 237, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 45%;
  min-width: 60px;
  width: fit-content;
  align-self: flex-end;
  margin-bottom: 20px;
}

.input-box {
  box-sizing: border-box;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(251, 240, 237, 1);
  border-radius: 16px;
  padding: 0 20px;
}

input:focus-visible {
  outline-offset: 0;
}

:focus-visible {
  outline: none;
}
</style>
