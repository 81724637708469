import {message} from "../../http/api/message";
import {commit} from "vuex-pathify";

const state = {
    /**
     * 0 群组
     * 1 单聊
     * id 群组id/单聊对象id
     */

    // conversion: [
    //     {
    //         type: 0,
    //         id: "mgsId",
    //         chat: [
    //             {
    //                 type: "txt",
    //                 // 设置消息内容。
    //                 msg: "",
    //                 // 设置消息接收方的用户 ID。
    //                 to: obj.to,
    //                 // 设置会话类型。
    //                 chatType: "groupChat",
    //                 ext: {
    //                     type: obj.type,
    //                     info: {"text": obj.content, "time": new Date()},
    //                 },
    //             }
    //         ]
    //     }
    // ],
    conversion: [],
    receptionChatList: []
}
const mutations = {
    saveMessage(state, obj) {
        console.log('pre', obj)
        let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))

        if (obj.type == 1) {
            if (obj.toUser == userInfo.code) {
                obj.id = obj.fromUser
            } else {
                obj.id = obj.toUser
            }
        }
        console.log('preSave', obj)
        obj.title = obj.title ? obj.title : obj.chat[0].fromUserInfo.nickname
        obj.avatar = obj.avatar ? obj.avatar : obj.chat[0].fromUserInfo.avatar

        let index = state.conversion.findIndex(c => c.id == obj.id)
        if (index === -1) {
            let newObj = Object.assign([], state.conversion)
            newObj.push(obj)
            state.conversion = newObj
        } else {
            if (obj.chat.length !== 0) {
                let chatArr = state.conversion[index].chat.find(c => c.msgId == obj.chat[0].msgId)
                if (!chatArr) {
                    state.conversion[index].chat.push(obj.chat[0])
                }
            } else {
                let index = state.conversion.findIndex(c => c.id == obj.id)
                if (index === -1) {
                    let newObj = Object.assign([], state.conversion)
                    newObj.push(obj)
                    state.conversion = newObj
                }
            }
        }
    },
    saveHistory(state, obj){
        debugger
        console.log('pre', obj)
        let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
debugger
        if (obj.type == 1) {
            if (obj.toUser == userInfo.code) {
                obj.id = obj.fromUser
            } else {
                obj.id = obj.toUser
            }
        }
        console.log('preSave', obj)
        obj.title = obj.title ? obj.title : obj.chat[0].fromUserInfo.nickname
        obj.avatar = obj.avatar ? obj.avatar : obj.chat[0].fromUserInfo.avatar

        let index = state.conversion.findIndex(c => c.id == obj.id)
        if (index === -1) {
            let newObj = Object.assign([], state.conversion)
            newObj.unshift(obj)
            state.conversion = newObj
        } else {
            if (obj.chat.length !== 0) {
                let chatArr = state.conversion[index].chat.find(c => c.msgId == obj.chat[0].msgId)
                if (!chatArr) {
                    state.conversion[index].chat.unshift(obj.chat[0])
                }
            } else {
                let index = state.conversion.findIndex(c => c.id == obj.id)
                if (index === -1) {
                    let newObj = Object.assign([], state.conversion)
                    newObj.unshift(obj)
                    state.conversion = newObj
                }
            }
        }
    },
    fetchReceptionMsg(state) {
        let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        message.fetchReceptionMsg().then(res => {
            res.data.map(x => {
                let cacheMessage = {
                    avatar: x.avatar,
                    title: x.nickname,
                    type: 'hospital',
                    from: userInfo.code,
                    id: x.id,
                    chat: []
                }
                commit('conversion/saveMessage', cacheMessage)
            })
            state.receptionChatList = res.data
            console.log(res)
        })
    },
    fetchReceptionList(state){
        message.fetchReceptionMsg().then(res => {
            state.receptionChatList = res.data
            console.log(res)
        })
    },
    clearConversion(state) {
        state.receptionChatList = []
        state.conversion = []
        sessionStorage.clear()
    }
}
const actions = {}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
