<template>
  <PageBody title="字典管理">
    <div style="width: 100%">
      <div style="display: flex;align-items: center">
        <v-row>
          <v-btn color="primary" style="margin-bottom: 30px" @click="openDialog">添加字典</v-btn>
          <v-subheader style="color: #FF957A;font-weight: 600">只允许添加字典和修改字典名称 请慎重!</v-subheader>
        </v-row>

      </div>

      <v-data-table
          style="width: 100%"
          :headers="headers"
          :items="tableData"
          :items-per-page="999"
          calculate-widths
          hide-default-footer
      >
        <template v-slot:item.parentId="{ item }">
          <div>{{ dicMapperMapperUtil(item.parentId) }}</div>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn color="primary" @click="editDict(item)">编辑</v-btn>
        </template>
      </v-data-table>
    </div>
    <AddDictDialog @confirm="dicConfirm" ref="addDictDialog"></AddDictDialog>
  </PageBody>
</template>

<script>
import PageBody from "../components/layout/PageBody";
// 状态(2:驱虫药(猫)3:驱虫药(狗) 4:疫苗(猫) 5:疫苗(狗) 6治疗对象 7专科专病 8语言)
import {dicMapper} from '../util/dicMapper'
import AddDictDialog from "../components/dialog/AddDictDialog";

export default {
  name: "DictionaryMgs",
  components: {AddDictDialog, PageBody},
  mounted() {
    this.fetchDict()
  },
  data() {
    return {
      headers: [
        {
          text: 'ID',
          align: 'center',
          sortable: false,
          value: 'id',
        },
        {
          text: '字典名称',
          align: 'center',
          sortable: false,
          value: 'name',
        },
        {
          text: '字典类型',
          align: 'center',
          sortable: false,
          value: 'parentId',
        },
        {
          text: '操作',
          align: 'center',
          sortable: false,
          value: 'action',
        },
      ],
      tableData: [],

    }
  },
  methods: {
    fetchDict() {
      this.$server.admin.fetchDict().then(res => {
        this.tableData = res.data
        console.log(res)
      })
    },
    dicMapperMapperUtil(val) {
      return dicMapper(val)
    },
    openDialog() {
      this.$refs.addDictDialog.showDialog()
    },
    dicConfirm() {
      this.fetchDict()
    },
    editDict(item) {
      this.$refs.addDictDialog.showDialog(item)
      console.log(item)
    }
  }
}
</script>

<style scoped>

</style>
