<template>
  <div>
    <v-dialog
        v-model="show"
        width="80%"
    >

      <v-card style="background-color: #E5E5E5">
        <v-card-title>
          订单详情
        </v-card-title>
        <v-card-text>
          <StadiumBox>
            <div style="width: 100%">
              <div style="display: flex;justify-content: space-between;align-items: center;">
                <div>
                  <h3>订单详情</h3>

                </div>
                <div style="display: flex;">
                  <v-btn color="primary" :disabled="!order.canConnect" @click="clickTextOrder">联系用户</v-btn>
                  <v-btn disabled style="margin-left: 20px" @click="refund" v-if="order.orderStatus == 232">已退款</v-btn>
                  <v-btn color="primary" style="margin-left: 20px" @click="refund" v-else>退款</v-btn>

                  <v-btn color="primary" style="margin-left: 20px" v-if="order.orderStatus == 202" @click="audit">通过审核
                  </v-btn>
                  <v-btn color="primary" style="margin-left: 20px" v-if="order.orderStatus == 211"
                         @click="openDeliverDialog">发货
                  </v-btn>
                </div>
              </div>
            </div>
          </StadiumBox>
          <StadiumBox style="margin-top: 30px">
            <div style="width: 100%">
              <v-row>
                <v-col>
                  <h3>
                    订单号：{{ order.sn }}

                  </h3>
                </v-col>
                <v-col style="text-align: center">
                  <h3>
                    下单时间：{{ $localDateTime( order.createTime) }}
                  </h3>
                </v-col>
                <v-col style="text-align: right">
                  <h3>
                    订单状态:{{ orderStatusMapper(order.orderStatus) }}
                  </h3>
                </v-col>
              </v-row>
            </div>
          </StadiumBox>
          <StadiumBox style="margin-top: 30px">
            <h3>

              发货清单
            </h3>
          </StadiumBox>
          <StadiumBox style="margin-top: 30px">
            <!--            <div style="width: 100%;padding: 0 30px;box-sizing: border-box">-->

            <!--              <v-row v-for="(item,index) in order.items" :key="index">-->
            <!--                {{ item.goodsName }} * {{ item.num }}-->
            <!--              </v-row>-->
            <!--            </div>-->

            <el-table
                :data="order.items"
                style="width: 100%">
              <el-table-column type="expand">

                <template slot-scope="scope">
                  <div>{{ JSON.stringify(scope) }}</div>

                </template>

              </el-table-column>
              <el-table-column
                  prop="itemSn"
                  label="sku编号">
              </el-table-column>
              <el-table-column
                  prop="goodsType"
                  label="商品分类">
                <template slot-scope="scope">
                  <span>{{ categoryConv(scope.row) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="goodsName"
                  label="名称">
              </el-table-column>
              <el-table-column
                  prop="num"
                  label="商品数量">
              </el-table-column>
              <el-table-column
                  prop="num"
                  label="退款数量">
                <template slot-scope="scope">
                  <span>{{ scope.row.refundNum ? scope.row.refundNum : 0 }}</span>
                </template>
              </el-table-column>

              <!--        <el-table-column-->
              <!--            prop="intro"-->
              <!--            label="说明">-->
              <!--        </el-table-column>-->
              <el-table-column
                  prop="goodsPrice"
                  label="单价">
                <template slot-scope="scope">
                  <span>{{ scope.row.sku.price / 100 }}元</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="num"
                  label="总价">
                <template slot-scope="scope">
                  <span>{{ (scope.row.sku.price) * (scope.row.num) / 100 }}元</span>
                </template>
              </el-table-column>


            </el-table>

          </StadiumBox>
          <StadiumBox style="margin-top: 30px">
            <h3>
              宠物信息
            </h3>
          </StadiumBox>
          <StadiumBox style="margin-top: 30px">
            <div style="width: 100%">
              <v-row>
                <v-col>姓名：{{ order.pet.name ? order.pet.name : "暂无姓名" }}</v-col>
                <v-col>年龄：{{ order.pet.birthday ? age(order.pet.birthday) : '未知' }}</v-col>
                <v-col>性别：{{
                    order.pet.gender === 0 ? '公' : order.pet.gender === 1 ? '母' : order.pet.gender === 2 ? '雌雄同体' : '不明'
                  }}
                </v-col>
                <v-col>绝育情况：{{ order.pet.neutered === 0 ? '未绝育' : order.pet.neutered === 1 ? '已绝育' : '未知' }}</v-col>
              </v-row>
              <!--              <v-row>-->
              <!--                <v-col>-->
              <!--                  病历：-->
              <!--                </v-col>-->
              <!--              </v-row>-->

              <template v-if="order.recipeInfo && order.recipeInfo.images.length != 0">
                <v-row>
                  <v-col>
                    处方图片
                  </v-col>
                </v-row>
                <v-row>
                  <v-img style="margin-bottom: 10px" max-height="140" max-width="140"
                         v-for=" (pic,index) in order.recipeInfo.images"
                         :key="index" :src="pic"></v-img>
                </v-row>
              </template>

              <template v-if="order.recipeInfo && order.recipeInfo.prescription">
                <v-row>
                  <v-col>
                    处方信息: {{ order.recipeInfo.prescription }}
                  </v-col>
                </v-row>
              </template>

              <v-row v-if="order.recipeInfo && order.recipeInfo.items">
                <v-col>
                  记录：
                  <template v-for="  (item,index) in order.recipeInfo.items">
                    <a @click="tapNote(item)" :key="index"> {{ item.categoryName }}</a>

                  </template>

                </v-col>
              </v-row>
              <v-row v-if="order.remark != ''">
                <v-col>
                  备注：{{ order.remark }}
                </v-col>
              </v-row>
            </div>
          </StadiumBox>
          <StadiumBox style="margin-top: 30px" v-if="order.orderStatus == 212">
            <h3>
              物流信息
            </h3>
          </StadiumBox>
          <StadiumBox style="margin-top: 30px" v-if="order.orderStatus == 212">
            <div style="width: 100%;height: 800px">
              <iframe style="width: 100%;height: 800px"
                      :src="`https://www.kuaidi100.com/chaxun?com=${order.logistics.logisticsCode}&nu=${order.logistics.waybillId}`"></iframe>
            </div>
          </StadiumBox>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="show = false"
          >
            取消
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="confirm()"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <RefundDialog ref="refundDialog" @confirm="confirm"></RefundDialog>
    <DeliverDialig ref="deliverDialog"></DeliverDialig>
    <IframeDialog ref="frameDialog"></IframeDialog>

  </div>
</template>

<script>
import StadiumBox from "../layout/StadiumBox";
import {orderStatusMapper} from "../../util/orderMapper";
import RefundDialog from "./RefundDialog";
import DeliverDialig from "./DeliverDialog";
import IframeDialog from "./IframeDialog";
import moment from 'moment'

export default {
  name: "OrderDetailDialog",
  components: {DeliverDialig, RefundDialog, StadiumBox, IframeDialog},

  props: {
    content: {
      type: String,
      default: "确定要删除吗？"
    }
  },
  data() {
    return {
      show: false,
      orderId: "",
      order: {},
    }
  },
  methods: {
    showDialog(orderId) {
      this.orderId = orderId
      this.fetchOrderDetail()
      this.show = true
    },
    fetchOrderDetail() {
      this.$server.order.fetchOrderDetail(this.orderId).then(res => {
        this.order = res.data
        console.log(this.order)
        this.$forceUpdate()
      })
    },
    confirm() {
      this.show = false
      this.$emit("confirm")
    },
    orderStatusMapper(val) {
      return orderStatusMapper(val)
    },
    clickTextOrder(item) {
      console.log(this.order)
      this.$router.push({
        path: '/admin/diagnoseOnline/chat',
        query: {
          type: 2,
          username: this.order.user.nickname,
          id: this.order.user.id
        }
      })
      console.log(item)
    },
    refund() {
      this.$refs.refundDialog.showDialog(this.order)
    },
    audit() {
      this.$server.order.orderVerify(this.order.sn, 1).then(res => {
        if (res.code === 0) {
          this.$message.success('订单审核通过')
          this.fetchOrderDetail()
        }
      })
    },
    openDeliverDialog() {
      this.$refs.deliverDialog.showDialog(this.order)
    },
    categoryConv(row) {
      console.log(row)
      switch (row.categoryId) {
        case 1:
          return '处方药';
        case 2:
          return '非处方药';
        case 3:
          return '保健品';
        case 4:
          return '用品';
        case 5:
          return '食品';

      }
    },
    age(birthday) {
      let age = ''
      let day = moment().diff(birthday, 'day')
      let year = Math.floor(day / 365)
      let y = day % 365
      let month = Math.floor(y / 30)
      let d = Math.floor(day % 365 % 30)
      age += year + '岁' + month + '月' + d + '天'
      return age
    },
    tapNote(item) {
      let url
      if (item.category == 114) {
        url = '/health/inspectionResault/inspectionResault'

      } else {
        url = '/pages/noteDetail/noteDetail'
      }
      let id = item.noteId

      let src = `${url}?id=${id}&isWeb=true&token=${sessionStorage.getItem('access_token')}`
      console.log(src)
      this.$refs.frameDialog.showDialog(src)
      console.log(item)
    },
    orderContact() {
      // const date1=moment(new  Date());
      // console.log(date1)
      // const date2=moment(‘18:00’,‘hh:mm’);
    }
  }
}
</script>

<style scoped>

</style>
