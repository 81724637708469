export const dicMapper = (type) => {
    console.log(type)
    switch (type) {
        case 1:
            return "记录类型"
        case 2:
            return "驱虫药（猫）"
        case 3:
            return "驱虫药（狗）"
        case 4:
            return "疫苗（猫）"
        case 5:
            return "疫苗（狗）"
        case 6:
            return "治疗对象"
        case 7:
            return "专科专病"
        case 8:
            return "语言"
    }
}
