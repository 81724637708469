import {post, put, remove,fetch} from "../http";

export const sys = {
    // 登录
    createAccount(data) {
        return post(`/sys/user`,data);
    },
    // 拉取账号列表
    getAccountList(currentPage,pageSize,data){
        return post(`/sys/user/records?currentPage=${currentPage}&pageSize=${pageSize}`,data);
    },
    // 拉取医生列表
    getDoctorList(currentPage,pageSize,hospitalId){
        return post(`/sys/user/doctor/records/page?currentPage=${currentPage}&pageSize=${pageSize}&hospitalId=${hospitalId}`);
    },
    // 修改密码
    modifyPassword(data){
        return put(`/sys/user/pwd`,data)
    },
    // 删除用户
    deleteUser(id){
        return remove(`/sys/user/${id}`)
    },
    //修改角色
    modifyRole(data){
        return post(`/sys/user/role`,data);
    },
    // 编辑医生信息
    modifyDoctorInfo(data){
        return post(`/sys/user/doctor`,data);
    },
    // 医生问诊编辑
    modifyDoctorInquiry(data){
        return post(`/sys/user/doctor/inquiry`,data)
    },
    // 医生问诊编辑
    getDoctorInfo(doctorId){
        return fetch(`/sys/user/doctor/detail/${doctorId}`)
    },
    // 修改医生问诊状态 1正常 2封禁 3删除
    modifyDoctorInquiryStatus(id,status){
        return put(`/sys/user/doctor/inquiry?id=${id}&status=${status}`)
    },
    // 修改医生状态
    modifyDoctorStatus(doctorId,status){
        return put(`/sys/user/doctor/status?doctorId=${doctorId}&status=${status}`)
    },
    modifyUserInfo(data){
        return put(`/sys/user/info`,data)
    },
    sendSms(mobile){
        return fetch(`/sys/user/qiniu/${mobile}`)
    },
    rePwd(userId){
        return put(`/sys/user/rePwd?userId=${userId}`)
    }
}
