import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {server} from '@/http/api'
import './assets/element-variables.scss'
import slideVerify from "vue-monoplasty-slide-verify";
import {localDateTime} from "./util/timeUtils";
import { Message,MessageBox,Upload,Table,TableColumn,Input,Dialog,DatePicker,Select,TimePicker,Option,Form,FormItem} from 'element-ui';
import VueDraggableResizable from 'vue-draggable-resizable'

// optionally import default styles
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import moment from "moment";

Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.config.productionTip = false
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$server = server;
Vue.prototype.store = store;
Vue.prototype.$moment = moment;
Vue.prototype.$localDateTime = localDateTime
Vue.prototype.$convBJ = localDateTime
Vue.prototype.$offsite = moment().utcOffset()/60;
Vue.use(Upload);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Select)
Vue.use(Option)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(slideVerify);

import { sync } from 'vuex-router-sync'
sync(store, router)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
