import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from "../views/LoginView";
// import LayoutComponents from "../components/layout/LayoutComponents";
import AccountMgsView from "../views/AccountMgsView";
import UpdatePassword from "../views/UpdatePassword";
import HospitalMgs from "../views/HospitalMgs";
// import DiagnoseOnline from "../views/DiagnoseOnline";
import AdminHospitalMgs from "../views/AdminHospitalMgs";
import ImLayout from "../components/layout/ImLayout";
import BookList from "../views/BookList";
import DiagnoseOnline from "../views/DiagnoseOnline";
import DoctorMgs from "../views/DoctorMgs";
import FinanceMgs from "../views/FinanceMgs";
import GoodsList from "../views/GoodsList";
import UserInfo from "../views/UserInfo";
import Index from "../layouts/default/Index";
import DictionaryMgs from "../views/DictionaryMgs";
import OrderMgs from "../views/OrderMgs";
import OrderList from "../views/OrderList";
import ExitView from "../views/ExitView";


Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginView
    },
    {
        path: '/admin',
        name: '宠小书医院端',
        component: Index,
        children: [
            {
                path: 'accountMgs',
                name: '员工管理',
                component: AccountMgsView
            }, {
                path: 'updatePassword',
                name: '修改密码',
                component: UpdatePassword
            }, {
                path: 'hospitalInfo',
                name: '医院信息',
                component: HospitalMgs
            },{
                path: 'doctorMgs',
                name: '医生管理',
                component: DoctorMgs
            },{
                path: 'userInfo',
                name: '用户信息',
                component: UserInfo
            },
            {
                path: 'diagnoseOnline',
                name: '在线问诊和客服',
                component: ImLayout,
                children: [
                    {
                        path: 'BookList',
                        name: '预约列表',
                        component: BookList
                    }, {
                        path: 'chat',
                        name: '聊天',
                        component: DiagnoseOnline
                    },
                ]
            }, {
                path: 'adminHospitalMgs',
                name: '医院管理',
                component: AdminHospitalMgs
            }, {
                path: 'financeMgs',
                name: '财务管理',
                component: FinanceMgs
            }, {
                path: 'orderMgs',
                name: '商店订单',
                component: OrderMgs
            }, {
                path: 'orderList',
                name: '订单列表',
                component: OrderList
            }, {
                path: 'goodsList',
                name: '商店库存',
                component: GoodsList
            } ,{
                path: 'dictionaryMgs',
                name: '字典管理',
                component:  DictionaryMgs
            },{
                path: 'exit',
                name: '登出账号中',
                component:  ExitView
            }
        ]
    },
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    // console.log(to)
    // console.log(from)
    // console.log(next)
    // next()
    if (to.name !== 'Login' && !sessionStorage.getItem("access_token")) next({name: 'Login'})
    else next()
})



// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }

export default router
