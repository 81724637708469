import { render, staticRenderFns } from "./SaveCaseDialog.vue?vue&type=template&id=efad31e2&scoped=true&"
import script from "./SaveCaseDialog.vue?vue&type=script&lang=js&"
export * from "./SaveCaseDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "efad31e2",
  null
  
)

export default component.exports