<template>
  <div>
    <PageBody title="医院信息">
      <div style="padding: 0 40px;width: 100%">
        <h5 style="margin-bottom: 40px">基础信息</h5>

        <v-form style="width: 100%">
          <v-row align="end" justify="space-around">
            <v-col>
              <v-row>
                <v-subheader style="width: 100px">头像</v-subheader>
                <el-upload
                    :headers="headers"
                    class="avatar-uploader"
                    :action="action"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload">
                  <img v-if="hospitalInfo.avatar " :src="hospitalInfo.avatar" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </v-row>
              <v-row align-content="center" @click="openAddressPicker">
                <v-subheader style="width: 100px">地址</v-subheader>
                <v-text-field disabled solo placeholder="请输入地址" v-model="hospitalInfo.address"></v-text-field>
              </v-row>
              <v-row>
                <v-subheader style="width: 100px">电话</v-subheader>
                <v-text-field solo placeholder="请输入电话" v-model="hospitalInfo.phone"></v-text-field>
              </v-row>
              <v-row>
                <v-subheader style="width: 100px">开业时间</v-subheader>
                <v-menu
                    ref="openDate"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="hospitalInfo.openingDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="hospitalInfo.openingDate"
                        placeholder="请选择开业时间"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        solo
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="hospitalInfo.openingDate "
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      取消
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.openDate.save(hospitalInfo.openingDate)"
                    >
                      确定
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
              <v-row>
                <v-subheader style="width: 100px">医院名称</v-subheader>
                <v-text-field solo placeholder="请输入医院名称" v-model="hospitalInfo.name"></v-text-field>
              </v-row>
              <v-row>
                <v-subheader style="width: 100px">面积</v-subheader>
                <v-text-field solo placeholder="请输入面积" type="number" v-model="hospitalInfo.size">
                  <template v-slot:append>
                    <div>平方米</div>
                  </template>
                </v-text-field>
              </v-row>
              <v-row>
                <v-subheader style="width: 100px">特色</v-subheader>
                <v-text-field solo placeholder="请输入特色" v-model="hospitalInfo.features"></v-text-field>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
        <h5 style="margin: 40px 0">营业时间</h5>
        <div style="display: flex;align-items: center">
          <v-btn color="primary" @click="openTime">添加营业时间</v-btn>

          <h4 style="color: red;margin-left: 20px">{{ $offsite != 8 ? '*此处按北京时间展示' :''}}</h4>
        </div>
        <el-table
            fit
            :data="hospitalInfo.businesses"
            style="width: 100%" :key="reFlush">
          <el-table-column
              prop="inquiryDate"
              label="日期">
            <template slot-scope="scope">
              <span v-if="scope.row.dateType == 3">每天</span>
              <span v-for="(item,index) in scope.row.openingDate"
                    :key="index">{{ dateCompute(scope.row.dateType, item) + '、' }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="name"
              label="时间">
            <template slot-scope="scope">
              <span>{{ scope.row.startTime }}~{{ scope.row.endTime }}</span>
            </template>
          </el-table-column>

          <el-table-column
              width="100"
              prop="address"
              label="操作">
            <template slot-scope="scope">
              <a v-if="scope.row.status == 1" @click="modifyStatus(scope,2)"
                 style="color: rgba(125, 172, 232, 1);text-decoration: underline">禁用</a>
              <a v-if="scope.row.status == 2" @click="modifyStatus(scope,1)"
                 style="color: rgba(125, 172, 232, 1);text-decoration: underline">启用</a>

              <a @click="modifyStatus(scope,3)"
                 style="color: rgba(125, 172, 232, 1);margin-left: 10px;text-decoration: underline">删除</a>
            </template>

          </el-table-column>
        </el-table>


        <h5 style="margin: 40px 0">医院环境</h5>
        <el-upload
            :action="action"
            :headers="headers"
            list-type="picture-card"
            :on-success="handleEnvSuccess"
            :file-list="envImageList"
            :on-preview="handlePictureCardPreview"
            :on-remove="handlePictureCardRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
        <h5 style="margin: 40px 0">设备</h5>
        <v-btn color="primary" @click="addEq">添加设备</v-btn>
        <div id="drag">
          <el-table
              class="drag-table"
              ref="dragTable"
              :data="hospitalInfo.equipments"
              style="width: 100%"
              :row-key="Math.random()"
          >
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="brand" label="品牌"></el-table-column>
            <el-table-column prop="version" label="型号"></el-table-column>
            <el-table-column prop="description" label="描述"></el-table-column>
            <el-table-column prop="images" label="照片">
              <template slot-scope="scope">
                <div style="display: flex">
                  <v-img max-width="40" max-height="40" v-for=" (item,index) in scope.row.images" :src="item"
                         :key="index"></v-img>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作">
              <template slot-scope="scope">
                <v-btn @click.stop="editEq(scope)">编辑</v-btn>
                <v-btn @click.stop="delEq(scope)" style="margin-left: 10px" color="error">删除</v-btn>
              </template>
            </el-table-column>

          </el-table>
        </div>


        <h5 style="margin: 40px 0">证照信息</h5>
        <el-upload
            :action="action"
            :headers="headers"
            list-type="picture-card"
            :on-success="handleCardSuccess"
            :on-preview="handleCardPreview"
            :file-list="cardImageList"
            :on-remove="handCardRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
        <h5 style="margin: 40px 0">医院介绍</h5>
        <v-textarea
            v-model="hospitalInfo.description"
            solo
            name="input-7-4"
            placeholder="请输入医院介绍"
        ></v-textarea>
        <div style="text-align: right">
          <v-btn color="primary" style="width: 95px;" @click="saveHospital">确认</v-btn>
        </div>
      </div>
    </PageBody>
    <v-dialog
        v-model="eqDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">添加设备</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-text-field
                  label="设备名称"
                  placeholder="请输入设备名称"
                  v-model="tempEqItem.name"
              ></v-text-field>
              <v-text-field
                  label="设备品牌"
                  placeholder="请输入设备品牌"
                  v-model="tempEqItem.brand"
              ></v-text-field>
              <v-text-field
                  label="设备型号"
                  placeholder="请输入设备型号"
                  v-model="tempEqItem.version"
              ></v-text-field>
              <v-textarea
                  label="设备描述"
                  v-model="tempEqItem.description"
                  placeholder="请输入设备描述"
              ></v-textarea>
            </v-form>
            <v-row>
              <v-col cols="12">
                <div style="margin-bottom: 20px">
                  <h3>设备照片</h3>
                </div>
                <el-upload
                    :action="action"
                    :headers="headers"
                    list-type="picture-card"
                    :file-list="eqImageList"
                    :on-success="handleEqSuccess"
                    :on-preview="handleEqPreview"
                    :on-remove="handleEqRemove">
                  <i class="el-icon-plus"></i>
                </el-upload>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeEqDialog"
          >
            关闭
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="eqConfirm"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <MapDialog ref="addressPicker" @confirm="addressConfirm"></MapDialog>

    <DateTimeSelectDialog ref="dateSelect" @confirm="(date)=>dateSelectConfirm(date)"></DateTimeSelectDialog>
  </div>

</template>

<script>
import PageBody from "../components/layout/PageBody";
import Sortable from 'sortablejs'
import {baseUrl} from "../http/http";
import DateTimeSelectDialog from "../components/dialog/DateTimeSelectDialog";
import MapDialog from "../components/dialog/MapDialog";
// import moment from "moment"
// 引用第三方拖拽插件
export default {
  name: "HospitalMgs",
  components: {
    MapDialog,
    DateTimeSelectDialog,
    PageBody,
  },
  data() {
    return {
      reFlush: true,
      baseUrl: baseUrl,
      imageUrl: '',
      time: null,
      menu2: false,
      eqDialog: false,
      menu: false,
      sortable: null,
      newList: [],
      dialogVisible: false,
      dialogImageUrl: [],
      envImageList: [],
      cardImageList: [],
      eqImageList: [],
      tempEqItem: {
        "name": "",
        "brand": "",
        "version": "",
        "description": "",
        "images": [],
      },
      openTimeForm: {
        "dateType": 0,
        "endTime": "string",
        "hospitalId": 0,
        "openingDateMonth": [],
        "openingDateWeek": [],
        timeRange: [0, 0],
        "startTime": "string",
        "status": 0,
        "updateTime": "2022-10-08T10:10:04.306Z"
      },
      eqDialogAction: {
        action: "",
        index: ""
      },
      openTimeDialog: false,
      hospitalInfo: {
        businesses: [],
        "avatar": "",
        "address": "",
        "phone": "",
        "openingDate": "",
        "name": "",
        // "startTime": "",
        // "endTime": "",
        "size": 0,
        "features": "",
        "images": [],
        "equipments": [
          // {
          //   "name": "",
          //   "brand": "",
          //   "version": "",
          //   "description": "",
          //   "images": [],
          // }
        ],
        "cards": [],
        "description": "",
        latitude: "",
        longitude: "",

      },
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      },
      action: `${baseUrl}/file/media `

    };
  },
  computed: {},
  mounted() {
    // this.setSort()

    this.initSortTable()

    this.fetchHospitalInfo()
  },
  methods: {
    initSortTable() {
      // 获取 tbody 节点
      const tbody = document.querySelector('#drag .el-table__body-wrapper tbody')
      // 拖拽排序
      Sortable.create(tbody, {
        delay: 0,
        animation: 300, // 拖拽延时，效果更好看
        onEnd: async (evt) => {
          this.hospitalInfo.equipments.splice(evt.newIndex, 0, this.hospitalInfo.equipments.splice(evt.oldIndex, 1)[0])
          this.hospitalInfo.equipments.forEach((item, index) => {
            item.sortNo = index + 1 // 重新给sortNo赋值
          })
          this.hospitalInfo.equipments.sort((a, b) => {
            return a.sortNo - b.sortNo
          })
          this.$forceUpdate()

          // 接口(param).then((res) => {})
        }
      })
    },
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        this.hospitalInfo.avatar = res.data
        this.$forceUpdate()
      }
    }
    ,
    handleEnvSuccess(res) {
      this.hospitalInfo.images.push(res.data)
    }
    ,
    handlePictureCardRemove(file, fileList) {
      let imageList = []
      fileList.forEach(x => {
        imageList.push(x.url)
      })
      this.hospitalInfo.images = imageList
    }
    ,

    handlePictureCardPreview() {
    }
    ,
    handleEqPreview() {
    }
    ,
    handleEqSuccess(res, file, fileList) {
      console.log(res)
      console.log(file)
      console.log(fileList)
      this.tempEqItem.images.push(res.data)
    }
    ,
    handleEqRemove(file, fileList) {
      let imageList = []
      fileList.forEach(x => {
        imageList.push(x.url)
      })
      this.tempEqItem.images = imageList
    }
    ,

    handleCardSuccess(res, file, fileList) {
      console.log(res)
      console.log(file)
      console.log(fileList)
      this.hospitalInfo.cards.push(res.data)
    }
    ,
    handleCardPreview() {
    }
    ,
    handCardRemove(file, fileList) {
      let imageList = []
      fileList.forEach(x => {
        imageList.push(x.url)
      })
      this.hospitalInfo.cards = imageList
    }
    ,
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isLt2M;
    }
    ,
    fetchHospitalInfo() {
      this.$server.hospital.hospitalInfo().then(res => {
        if (res.code === 0) {
          this.hospitalInfo = Object.assign(this.hospitalInfo, res.data)
          this.newList = this.hospitalInfo.equipments
          this.envImageList = this.hospitalInfo.images.map(item => {
            return {
              name: item,
              url: item
            }
          });
          this.cardImageList = this.hospitalInfo.cards.map(item => {
            return {
              name: item,
              url: item
            }
          });

          this.$forceUpdate()
        }
      })
    }
    ,
    saveHospital() {
      let data = Object.assign({}, this.hospitalInfo)
      this.$server.hospital.saveOrUpdateHospitalInfo(data).then(res => {
        if (res.code === 0) {
          this.$message.success("保存医院信息成功")
          this.fetchHospitalInfo()
        }
      })
    }
    ,
    addEq() {
      this.eqDialogAction.action = "add"
      this.eqDialog = true
    }
    ,
    closeEqDialog() {
      this.eqDialog = false
      this.tempEqItem.images = []
      this.tempEqItem.description = ""
      this.tempEqItem.name = ""
      this.tempEqItem.version = ""
      this.tempEqItem.brand = ""
      this.eqDialogAction.action = ""
      this.eqImageList = []
      this.index = 0
      this.$forceUpdate()
    }
    ,
    eqConfirm() {
      this.eqDialog = false
      let data = Object.assign({}, this.tempEqItem)

      if (this.eqDialogAction.action === "add") {
        this.hospitalInfo.equipments.push(data)
        this.saveHospital();
      } else if (this.eqDialogAction.action === "edit") {
        this.hospitalInfo.equipments[this.eqDialogAction.index] = data
        this.saveHospital();
      }
      this.tempEqItem.images = []
      this.tempEqItem.description = ""
      this.tempEqItem.name = ""
      this.tempEqItem.version = ""
      this.tempEqItem.brand = ""
      this.eqDialogAction.action = ""
      this.eqImageList = []
      this.index = 0
      this.$forceUpdate()
    }
    ,
    editEq(scope) {
      this.eqDialog = true
      this.eqDialogAction.action = "edit"
      this.eqDialogAction.index = scope.$index
      let newTemp = Object.assign({}, scope.row)
      let imgArr = []
      newTemp.images.forEach(item => {
        imgArr.push({
          name: item,
          url: item
        })
      })
      this.eqImageList = imgArr
      this.tempEqItem = newTemp
    }
    ,
    openTime() {
      this.$refs.dateSelect.open()
    }
    ,
    dateCompute(dateType, item) {
      switch (dateType) {
        case 1:
          return `每月${item + 1}日`
        case 2:
          switch (item + 1) {
            case 1:
              return `每周一`
            case 2:
              return `每周二`
            case 3:
              return `每周三`
            case 4:
              return `每周四`
            case 5:
              return `每周五`
            case 6:
              return `每周六`
            case 7:
              return `每周日`
          }
          break
        case 3:
          console.log(123123123123)
          return `每周日`
      }
    }
    ,
    dateSelectConfirm(data) {
      data.openingDate = data.inquiryDate
      this.hospitalInfo.businesses.push(data)
      this.saveHospital()
      console.log(data)
    }
    ,
    modifyStatus(scope, status) {
      this.reFlush = !this.reFlush
      let index = scope.$index
      this.hospitalInfo.businesses[index].status = status
      this.saveHospital()
    }
    ,
    delEq(scope) {
      this.$confirm('确定删除设备信息吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let index = scope.$index
        this.hospitalInfo.equipments.splice(index, 1)
        this.saveHospital()
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    }
    ,
    addressConfirm(address) {
      console.log(address)
      this.hospitalInfo.address = address.poiaddress
      this.hospitalInfo.latitude = address.latlng.lat
      this.hospitalInfo.longitude = address.latlng.lng
    }
    ,
    openAddressPicker() {
      this.$refs.addressPicker.showDialog()
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 140px;
  line-height: 140px;
  text-align: center;
  padding-top: 56px;
}

.avatar {
  width: 140px;
  height: 140px;
  display: block;
}

.v-application ul, .v-application ol {
  padding-left: 0px;
}
</style>
