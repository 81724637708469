<template>
  <PageBody title="修改密码">
    <div style="display: flex;flex-direction: column;align-items: center;width: 100%">
      <v-form style="width: 400px" lazy-validation ref="form">
        <div style="display: flex;">
          <div style="width: 80%">
            <v-text-field
                label="原密码"
                placeholder="请输入原密码"
                v-model="formData.oldPwd"
                type="password"
                :rules="oldPasswordRules"
            ></v-text-field>
          </div>
        </div>
        <div style="display: flex;">
          <div style="width: 80%">
            <v-text-field
                placeholder="请输入新密码"
                label="新密码"
                type="password"
                v-model="formData.newPwd"
                :rules="newPassword"
            ></v-text-field>
          </div>
        </div>
        <div style="display: flex;">
          <div style="width: 80%">
            <v-text-field
                placeholder="请再次输入原密码"
                label="再次输入密码"
                type="password"
                v-model="formData.reNewPwd"
                :rules="repNewPassword"
            ></v-text-field>
          </div>
        </div>
      </v-form>
      <div style="display: flex;">
        <v-btn color="primary" width="95" @click="confirm">确认</v-btn>
      </div>
    </div>
  </PageBody>
</template>

<script>
import PageBody from "../components/layout/PageBody";

export default {
  name: "UpdatePassword",
  components: {PageBody},
  data() {
    return {
      formData: {
        newPwd: "",
        oldPwd: "",
        reNewPwd: ""
      },
      oldPasswordRules: [
        v => !!v || '请输入原密码',
      ],
      newPassword: [
        v => !!v || '请输入新密码',
        v => (/^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.\\;'[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、/]).*$/.test(v)) || '密码最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符'
      ],
      repNewPassword: [
        v => !!v || '请再次输入新密码',
        v => !(v !== this.formData.newPwd) || '两次密码输入不一致',
      ],
      // password: [
      //   v => !!v || '请输入密码',
      // ],
      // codeRules: [
      //   v => !!v || '请输入验证码',
      // ],
      // repPassword: [
      //   v => !!v || '请再次输入密码',
      //   v => !(v !== this.formData.password) || '两次密码输入不一致',
      // ]
    }
  },
  methods: {
    confirm() {
      if (this.$refs.form.validate()) {
        this.formData.username = JSON.parse(sessionStorage.getItem("userInfo")).username
        this.$server.sys.modifyPassword(this.formData).then(res => {
          if (res.code === 0) {
            this.$message.success("密码修改成功，请重新登录")
            setTimeout(() => {
              sessionStorage.clear()
              this.$store.commit("imClose")
              location.reload()
            }, 3000)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    }
  },

}
</script>

<style scoped>

</style>
