<template>
  <PageBody title="订单列表">
    <div style="width: 100%">
      <v-row style="width: 100%" justify="space-between">
        <v-col>
          <div style="display: flex;align-items: center">
            <h5>起始时间：</h5>
            <el-date-picker
                v-model="formData.dateRange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
            <h5 style="margin-left: 30px">医院名称：</h5>
            <el-select v-model="formData.hospitalId" filterable placeholder="请选择医院">
              <el-option
                  v-for="item in hospitalList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </v-col>
        <v-col align="end" cols="3">
          <v-btn cols="1" color="primary" style="margin-right: 10px" @click="formData = {}">清除</v-btn>
          <v-btn cols="1" color="primary" @click="search">查询</v-btn>
        </v-col>

      </v-row>
      <v-row>
        <v-col cols="1">
          <v-btn color="primary" @click="remitBatch()">批量打款</v-btn>
        </v-col>
      </v-row>
      <div style="width: 100%;margin-top: 30px">
        <v-data-table
            v-model="selected"
            style="width: 100%"
            :headers="headers"
            :items="tableData"
            :items-per-page="20"
            :single-select="false"
            show-select
            calculate-widths
            hide-default-footer
        >
          <template v-slot:top>
          </template>
          <template v-slot:item.orderStatus="{ item }">
            <div>{{ orderStatusMapper(item.orderStatus) }}</div>
          </template>
          <template v-slot:item.goodsPrice="{ item }">
            <div>{{ item.updatePrice / 100 }} ¥</div>
          </template>
          <template v-slot:item.action="{ item }">
            <a v-if="item.orderStatus == 132 || item.orderStatus == 232"
               style="color:#999999;">已退款</a>

            <a v-else-if="item.orderStatus == 300" style="color: #999999;">已打款</a>
            <a v-else style="color: rgba(125, 172, 232, 1);text-decoration: underline" @click="remit({
            sns:[item.sn]
            } )">打款</a>
          </template>
        </v-data-table>
        <v-pagination
            v-model="page.currentPage"
            :length="page.totalPage"
            :total-visible="20"
            circle
            @input="onPageChange"
        >
        </v-pagination>
      </div>

    </div>
  </PageBody>
</template>

<script>
import {orderStatusMapper} from "../util/orderMapper";
import PageBody from "../components/layout/PageBody";
import moment from "moment";

export default {
  name: "FinanceMgs",
  components: {PageBody},
  data() {
    return {
      formData: {
        dateRange: [],
        hospitalId: ""
      },
      page: {
        currentPage: 1,
        pageSize: 20,
        totalPage: 0
      },
      headers: [
        {
          text: '订单号',
          align: 'center',
          sortable: false,
          value: 'sn',
        }, {
          text: '下单时间',
          align: 'center',
          sortable: false,
          value: 'createTime',
        }, {
          text: '费用',
          align: 'center',
          sortable: false,
          value: 'goodsPrice',
        }, {
          text: '订单状态',
          align: 'center',
          sortable: false,
          value: 'orderStatus',
        }, {
          text: '医院名称',
          align: 'center',
          sortable: false,
          value: 'hospitalName',
        }, {
          text: '操作',
          align: 'center',
          sortable: false,
          value: 'action',
        },
      ],
      tableData: [],
      singleSelect: [],
      hospitalList: [],
      currentHospitalId: "",
      selected: []
    }
  },
  mounted() {
    this.fetchFinanceList()
    this.fetchHospitalList()

  },
  methods: {
    fetchFinanceList() {
      let data = {}
      if (this.formData.hospitalId != '') {
        data.hospitalId = this.formData.hospitalId
      }
      this.$server.admin.fetchAdminOrderList(this.page.currentPage, this.page.pageSize, data).then(res => {
        res.data.content.map(x => {
          x.createTime = this.$localDateTime(x.createTime)
        })
        this.tableData = res.data.content

        this.page.totalPage = res.data.totalPages
        console.log(res)
      })
    },
    fetchHospitalList() {
      this.$server.hospital.fetchAllHospitalList().then(res => {
        res.data.map(x => {
          x.label = x.name
          x.value = x.id
        })
        this.hospitalList = res.data
        console.log(res)
      })
    },
    onPageChange(event) {
      this.fetchFinanceList()
      console.log(event)
    },
    orderStatusMapper(val) {
      return orderStatusMapper(val)
    },
    remit(data) {
      this.$server.admin.orderRemit(data).then(res => {
        if (res.code === 0) {
          this.$message.success('订单打款成功')
          this.fetchFinanceList()
        }
      })
    },
    remitBatch() {
      let data = {
        sns: []
      }
      this.selected.map(x => data.sns.push(x.sn))
      this.$server.admin.orderRemit(data).then(res => {
        if (res.code === 0) {
          this.$message.success('订单批量打款成功')
          this.selected = []
          this.fetchFinanceList()
        }
      })
    },
    search() {
      this.page.currentPage = 1
      this.page.pageSize = 20
      if (this.formData.dateRange.length != 0) {

        let data = {
          endTime: moment(this.formData.dateRange[1]).format('YYYY-MM-DD HH:mm:ss',),
          startTime: moment(this.formData.dateRange[0]).format('YYYY-MM-DD HH:mm:ss',),
          orderType: 1
        }

        this.page.currentPage = 1
        this.page.pageSize = 20

        this.$server.admin.fetchAdminOrderList(this.page.currentPage, this.page.pageSize, data).then(res => {
          res.data.content.map(x => {
            x.createTime = this.$localDateTime(x.createTime)
          })
          this.tableData = res.data.content
          this.page.totalPage = res.data.totalPages
          this.formData.dateRange = []
        })
      } else {
        this.page.currentPage = 1
        this.page.pageSize = 20
        this.fetchFinanceList()
      }
    }
  }
}
</script>

<style scoped>

</style>
