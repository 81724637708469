<template>
  <div>
    <v-dialog
        v-model="show"
        width="800"
    >
      <v-card>
        <v-card-title>
          {{dialogType==="save"?`添加字典`:`编辑字典`}}
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row align="center">
              <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
              >
                <v-select
                    :disabled="dialogType === 'edit'"
                    v-model="formData.parentId"
                    :items="items"
                    label="选择字典类型"
                    solo
                    placeholder="请选择字典类型"
                ></v-select>
              </v-col>
              <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
              >
                <v-text-field
                    v-model="formData.name"
                    label="字典值"
                    solo
                    placeholder="请输入字典值"></v-text-field>
              </v-col>

            </v-row>
          </v-container>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="show = false"
          >
            取消
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="confirm()"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AddDictDialog",
  props: {
    content: {
      type: String,
      default: "确定要删除吗？"
    }
  },
  data() {
    return {
      show: false,
      dialogType:"save",
      formData: {
        "name": "",
        "parentId": "",
      },
      items: [{
        text: '记录类型',
        value: 1
      }, {
        text: '驱虫药（猫）',
        value: 2
      }, {
        text: '驱虫药（狗）',
        value: 3
      }, {
        text: '疫苗（猫）',
        value: 4
      }, {
        text: '疫苗（狗）',
        value: 5
      }, {
        text: '治疗对象',
        value: 6
      }, {
        text: '专科专病',
        value: 7
      }, {
        text: '语言',
        value: 8
      },
      ]
    }
  },
  methods: {
    showDialog(item) {
      this.formData = {}
      this.dialogType = "save"
      if(item){
        this.dialogType='edit'
        this.formData.name = item.name
        this.formData.parentId = item.parentId
        this.formData.id = item.id
      }
      this.show = true
    },
    confirm() {
      this.$server.admin.saveOrUpdateDict(this.formData).then(res => {
        if (res.code === 0) {
          this.$message.success("添加字典成功")
          this.$emit("confirm")
        }
      })
      this.show = false
      this.$emit("confirm")

    }
  }
}
</script>

<style scoped>

</style>
