<template>
  <div>
    <div class="background-bar">
    </div>
    <div style="text-align: center">
      <img style="height: 145px;width: 145px" src="../assets/logo.png"/>
      <h1>宠小书医院管理系统</h1>
      <div style="width: 20%;margin: 0 auto;margin-top: 20px">
        <v-form
            lazy-validation ref="form">
          <v-text-field
              v-model="user.username"
              label="请输入用户名"
              placeholder="请输入用户名"
              solo></v-text-field>
          <v-text-field
              type="password"
              v-model="user.password"
              label="请输入密码"
              placeholder="请输入密码"
              solo></v-text-field>
          <!--          <v-row>-->
          <!--            <v-col>-->
          <!--              <v-text-field label="请输入验证码"-->
          <!--                            placeholder="请输入验证码"-->
          <!--                            solo></v-text-field>-->
          <!--            </v-col>-->
          <!--            <v-col>-->
          <!--              <img :src="code" />-->
          <!--            </v-col>-->
          <!--          </v-row>-->
          <v-container ref="con" fluid style="padding: 0">
            <slide-verify
                :l="slideObj.l"
                :r="slideObj.r"
                :w="slideObj.w"
                :h="slideObj.h"
                slider-text="请拖动滑块完成验证"
                @success="onSuccess"
                @fail="onFail"
                @refresh="onRefresh"
                :imgs="imgs"
                :accuracy='5'
                ref="slideblock"
            ></slide-verify>
          </v-container>


        </v-form>
        <v-btn style="margin-top: 30px" width="95" height="40" color="primary" @click="login">登录</v-btn>
      </div>


    </div>
  </div>

</template>

<script>
export default {
  name: "LoginView",
  data() {
    return {
      user: {
        username: undefined,
        password: undefined
      },
      code: "",
      slideObj: {
        l: 42, //滑动碎片的大小
        r: 10, //滑动碎片的圆角
        w: 310, //画布的宽
        h: 155, //画布的高
      },
      demo: false,
      lock: true,
      imgs: [
        "https://cloud.yuhoulianghao.com/statics/c-01.jpg",
        "https://cloud.yuhoulianghao.com/statics/c-02.jpg",
        "https://cloud.yuhoulianghao.com/statics/c-03.jpg",
        "https://cloud.yuhoulianghao.com/statics/c-04.jpg",
        "https://cloud.yuhoulianghao.com/statics/c-05.jpg",
        "https://cloud.yuhoulianghao.com/statics/c-06.png",
        "https://cloud.yuhoulianghao.com/statics/c-07.jpg",
        "https://cloud.yuhoulianghao.com/statics/c-08.jpg",
        "https://cloud.yuhoulianghao.com/statics/c-09.jpg",
        "https://cloud.yuhoulianghao.com/statics/c-10.jpg",
        "https://cloud.yuhoulianghao.com/statics/c-11.jpg",
        "https://cloud.yuhoulianghao.com/statics/c-12.jpg",
        "https://cloud.yuhoulianghao.com/statics/c-13.jpg",
        "https://cloud.yuhoulianghao.com/statics/c-14.jpg",
        "https://cloud.yuhoulianghao.com/statics/c-15 .jpg",

      ], //可以将所需的图片放在一个数组中，刷新后组件会自动随机抽取图片
    }
  },
  mounted() {
    this.slideObj.w =   this.$refs.con.offsetWidth
    this.getCode()
  },
  methods: {
    login() {
      if (!this.lock) {
        this.$server.user.login(this.user.username, this.user.password).then(res => {
          if (res.code === 1) {
            this.$refs.slideblock.reset();
            this.lock = true
            this.$message({
              type: "error",
              message: "账号或密码错误"
            })
          } else if (res.code === 0) {
            let userInfo = res.data.userinfo
            userInfo.password = this.user.password
            sessionStorage.setItem("access_token", res.data.accessToken)
            sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
            // this.$store.state.roles = res.data.userinfo.roles
            // this.$store.commit('addRoles', res.data.userinfo.roles)
            // switch (res.data.userinfo.roles[0]) {
            //   case "SYS_ADMIN":
            //     this.$router.push("/admin/adminHospitalMgs")
            //     break;
            //   case "ADMIN":
            //     this.$router.push("/admin")
            // }
            this.$router.push("/admin")
            setTimeout(() => {
              location.reload();
            }, 1000)
            /**
             * 登录环信
             */
            this.imLogin()
          }
          console.log(res)
        })
      } else {
        this.$message.error('请拖动滑块')
      }

    },
    getCode() {
      this.$server.noauth.fetchCode().then(res => {
        this.code = res
        console.log(res)
      })
    },
    imLogin() {
      let req = {
        username: this.user.code,
        pwd: 123456,
        nickname: ""
      }
      this.$store.commit("im/imInit", req)
    },
    onSuccess() {
      this.lock = false
      console.log("成功回调");
    },
    onFail() {
      console.log("失败回调");
    },
    onRefresh() {
      console.log("刷新回调");
    },
  }
}
</script>

<style scoped>
.background-bar {
  background: linear-gradient(180deg, rgba(255, 108, 71, 1) 0%, rgba(250, 250, 250, 0.01) 100%);
  height: 100px;
}
</style>
