<template>
  <div>
    <StadiumBox style="margin-top: 30px">
      {{ userInfo.nickname }}的资料
    </StadiumBox>
    <StadiumBox style="height: fit-content;margin-top: 30px">
      <div style="width: 100%">
        <v-row style="height: 100%" align="center">
          <v-col>
            <h5>用户头像</h5>
            <el-upload
                class="avatar-uploader"
                :action="action"
                :headers="headers"
                :show-file-list="false"
                :on-success="handleAvatarSuccess">
              <img v-if="userInfo.avatar" :src="userInfo.avatar" class="avatar"/>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </v-col>
          <v-spacer></v-spacer>
          <v-col align-self="start"
                 style="display: flex;flex-direction: column;justify-content: space-between;height: 100%">
            <v-row style="margin-top: 30px" align="center">
              <h5>对外显示名称：</h5>
              <v-text-field v-model="userInfo.displayName" hide-details solo placeholder="请输入对外显示名称"></v-text-field>
            </v-row>
            <v-row style="margin-top: 30px" align="center">
              <h5 style="">姓名：</h5>
              <v-text-field v-model="userInfo.nickname" placeholder="请输入姓名" hide-details solo></v-text-field>
            </v-row>
          </v-col>
        </v-row>
        <v-btn color="primary" @click="saveUserInfo">保存</v-btn>
      </div>
    </StadiumBox>
    <template v-if="roles.includes('DOCTOR')">
      <StadiumBox style="margin-top: 30px">
        医生信息
      </StadiumBox>
      <StadiumBox style="margin-top: 30px">
        <div style="width: 100%">
          <h5>简介</h5>
          <div style="width: 100%;margin-top: 30px">
            <v-textarea v-model="doctorInfo.description" solo placeholder="请输入医生简介"></v-textarea>
          </div>
          <v-row style="margin-top: 30px" align="center">
            <h5>独立坐诊时间</h5>
            <div style="width: 180px;margin: 0 10px">
              <v-text-field hide-details solo v-model="doctorInfo.duration"></v-text-field>
            </div>
            <h5>年</h5>
          </v-row>
          <v-row style="margin-top: 30px" align="center">
            <h5>诊疗对象：</h5>
            <div>
              <el-select v-model="doctorInfo.treatments" multiple placeholder="请选择">
                <el-option
                    v-for="item in objectSelect"
                    :key="item.value"
                    :label="item.name"
                    :value="item.id + '' ">
                </el-option>
              </el-select>
            </div>
          </v-row>
          <v-row style="margin-top: 30px" align="center">
            <h5>专科：</h5>
            <div style="">
              <el-select v-model="doctorInfo.careers" multiple placeholder="请选择">
                <el-option
                    v-for="item in subjectSelect"
                    :key="item.value"
                    :label="item.name"
                    :value="item.id+''">
                </el-option>
              </el-select>
            </div>
          </v-row>
          <v-row style="margin-top: 30px" align="center">
            <h5>语言：</h5>

            <div style="">
              <el-select v-model="doctorInfo.languages" multiple placeholder="请选择">
                <el-option
                    v-for="item in languageSelect"
                    :key="item.value"
                    :label="item.name"
                    :value="item.id+''">
                </el-option>
              </el-select>
            </div>
          </v-row>
          <v-row style="margin-top: 30px">
            <h5>证书</h5>
          </v-row>
          <v-row>
            <span class="text-subtitle-2">请上传《执业兽医资格证书》和其他相关证书。</span>
          </v-row>
          <v-row style="margin-top: 30px">
            <el-upload
                :action="action"
                :headers="headers"
                list-type="picture-card"
                :file-list="userInfoImageList"
                :on-success="handleSuccess"
                :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </v-row>
          <v-row style="margin-top: 50px">
            <v-btn color="primary" @click="saveDoctorInfo">保存</v-btn>
          </v-row>

        </div>
      </StadiumBox>
    </template>

  </div>

</template>

<script>
import StadiumBox from "../components/layout/StadiumBox";
import {baseUrl} from "../http/http";

export default {
  name: "UserInfo",
  components: {StadiumBox,},
  data() {
    let roles = JSON.parse(sessionStorage.getItem('userInfo')).roles
    return {
      userInfoImageList: [],
      doctorInfo: {
        cards: [],
        careers: [],
        description: "",
        duration: 0,
        languages: [],
        serviceCharge: 0,
        status: 0,
        treatments: [],
        userId: 0,
      },
      baseUrl,
      userInfo: {},
      roles: roles,
      objectSelect: [],
      subjectSelect: [],
      languageSelect: [],
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      },
      action: `${baseUrl}/file/media `
    }
  },
  mounted() {
    // this.fetchDict()
    this.fetchDoctorInfo()
    this.fetchDict(6, 'objectSelect')
    this.fetchDict(7, 'subjectSelect')
    this.fetchDict(8, 'languageSelect')
  },
  methods: {
    handleAvatarSuccess(res) {
      console.log(res)
      this.userInfo.avatar = res.data
      this.$forceUpdate()

    },
    fetchDoctorInfo() {
      let doctorId = JSON.parse(sessionStorage.getItem("userInfo")).id
      this.$server.sys.getDoctorInfo(doctorId).then(res => {
        this.userInfo = res.data
        this.doctorInfo = res.data
        if (!this.doctorInfo.cards) {
          this.doctorInfo.cards = []
        }
        this.userInfoImageList = res.data.cards.map(item => {
          return {
            name: item,
            url: item
          }
        });
      })
    },
    saveUserInfo() {
      this.userInfo.id = JSON.parse(sessionStorage.getItem("userInfo")).id
      this.$server.sys.modifyUserInfo(this.userInfo).then(res => {
        if (res.code === 0) {
          this.$message.success("医生信息保存成功")
        }
      })
    },
    fetchDict(type, fieldName) {
      this.$server.noauth.fetchDict(type).then(res => {
        this[fieldName] = res.data
      })
    },
    saveDoctorInfo() {
      this.doctorInfo.userId = JSON.parse(sessionStorage.getItem("userInfo")).id
      this.$server.sys.modifyDoctorInfo(this.doctorInfo).then(res => {
        if (res.code === 0) {
          this.$message.success("保存医生信息成功")
        }
      })
    },
    handleSuccess(res) {
      console.log(res)
      this.doctorInfo.cards.push(res.data)
    },
    handleRemove(file, fileList) {
      let imageList = []
      fileList.forEach(x => {
        imageList.push(x.url)
      })
      this.doctorInfo.cards = imageList
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
