import EC from 'easemob-websdk';
import {commit,} from "vuex-pathify";
import {message as messageApi} from '../../http/api/message'
import moment from "moment";
//import {Notification} from 'element-ui';
import {convBJ} from "../../util/timeUtils";

const state = {
    conn: undefined,
    conversion: [
        {
            chat: []
        }
    ]
}

const mutations = {
    imInit(state, obj) {
        state.conn = new EC.connection({
            appKey: "1119220821113133#chongxiaoshu",
        });
        state.conn.open({
            user: obj.username,
            pwd: obj.pwd,
        }).then(() => {
            console.log("im", "login success");
        }).catch((reason) => {
            console.log("login fail", reason);
        });
        state.conn.addEventHandler("eventName", {
            // SDK 与环信服务器连接成功。
            onConnected: function () {
                // console.log(message)
            },
            // SDK 与环信服务器断开连接。
            onDisconnected: function (message) {
                console.log(message)
            },
            // 当前用户收到文本消息。
            onTextMessage: function (message) {
                commit("conversion/fetchReceptionList")
                //Notification({
                    //title: '您收到一条消息',
                   // message: `来自${message.ext.fromUserInfo.nickname}`,
                    //type: 'success'
                //});
                if(document.hidden) {
                    if (!window.Notification) {
                        console.log('浏览器不支持通知');
                    } else {
                        // 检查用户曾经是否同意接受通知
                        if (Notification.permission === 'granted') {
                            var notification = new Notification("宠小书新消息", {body: `您收到来自 ${message.ext.fromUserInfo.nickname} 新的消息`});
                            notification.onclick = function () {
                                window.parent.focus();
                                notification.close();
                            }
                        } else if (Notification.permission === 'default') {
                            // 用户还未选择，可以询问用户是否同意发送通知
                            Notification.requestPermission().then(permission => {
                                if (permission === 'granted') {
                                    console.log('用户同意授权');
                                    var notification = new Notification("宠小书新消息", {body: `您收到来自 ${message.ext.fromUserInfo.nickname} 新的消息`}); // 显示通知
                                    notification.onclick = function () {
                                        window.parent.focus();
                                        notification.close();
                                    }
                                } else if (permission === 'default') {
                                    console.warn('用户关闭授权 未刷新页面之前 可以再次请求授权');
                                } else {
                                    // denied
                                    console.log('用户拒绝授权 不能显示通知');
                                }
                            });
                        } else {
                            // denied 用户拒绝
                            console.log('用户曾经拒绝显示通知');
                        }
                    }
                }
                console.log('收到的消息', message)

                message.ext.msgId = message.ext.id
                let cacheMessage = {
                    chat: [
                        message.ext,
                    ]
                }
                if (message.chatType === 'groupChat') {
                    cacheMessage.type = 0
                    cacheMessage.id = message.to
                    try {
                        commit("order/countPlus", message.to)
                    } catch (e) {
                        console.log(e)
                    }
                } else {
                    cacheMessage.type = 1
                    cacheMessage.id = message.from
                    cacheMessage.toUser = message.from
                    // cacheMessage.msgId = message.id
                    cacheMessage.chat[0].msgId = message.id
                    try {
                        commit("order/countPlus", message.from)
                    } catch (e) {
                        console.log(e)
                    }

                }
                console.log('预保存的消息', cacheMessage)
                commit("conversion/saveMessage", cacheMessage)
            },
            // 当前用户收到图片消息。
            onImageMessage: function (message) {
                console.log(message)
            },
            // 当前用户收到透传消息。
            onCmdMessage: function (message) {
                console.log(message)
            },
            // 当前用户收到语音消息。
            onAudioMessage: function (message) {
                console.log(message)
            },
            // 当前用户收到位置消息。
            onLocationMessage: function (message) {
                console.log(message)
            },
            // 当前用户收到文件消息。
            onFileMessage: function (message) {
                console.log(JSON.stringify(message))
            },
            // 当前用户收到自定义消息。
            onCustomMessage: function (message) {
                console.log(message)
            },
            // 当前用户收到视频消息。
            onVideoMessage: function (message) {
                console.log(message)
            },
            // 当前用户订阅的其他用户的在线状态更新。
            onPresence: function (message) {
                console.log(message)
            },
            // 当前用户收到好友邀请。
            onContactInvited: function (message) {
                console.log(message)
            },
            // 联系人被删除。
            onContactDeleted: function (message) {
                console.log(message)
            },
            // 新增联系人。
            onContactAdded: function (message) {
                console.log(message)
            },
            // 当前用户发送的好友请求被拒绝。
            onContactRefuse: function (message) {
                console.log(message)
            },
            // 当前用户发送的好友请求被同意。
            onContactAgreed: function (message) {
                console.log(message)
            },
            // 当前用户收到群组邀请。
            onGroupEvent: function (message) {
                console.log(message)
            },
            // 本机网络连接成功。
            onOnline: function (message) {
                console.log(message)
            },
            // 本机网络掉线。
            onOffline: function (message) {
                console.log(message)
            },
            // 调用过程中出现错误。
            onError: function (message) {
                console.log(message)
            },
            // 当前用户收到的消息被消息发送方撤回。
            onRecallMessage: function (message) {
                console.log(message)
            },
            // 当前用户发送的消息被接收方收到。
            onReceivedMessage: function (message) {
                console.log(message)
            },
            // 当前用户收到消息送达回执。
            onDeliveredMessage: function (message) {
                console.log(message)
            },
            // 当前用户收到消息已读回执。
            onReadMessage: function (message) {
                console.log(message)
            },
            // 当前用户收到会话已读回执。
            onChannelMessage: function (message) {
                console.log(message)
            },
        });
    },
    imClose(state) {
        state.conn.close();
        console.log("im", "im退出成功")
    },
    sendCall(state, obj) {
        let customEvent = "call";
        let customExts = {};
        let option = {
            // 消息类型。
            type: "custom",
            // 消息接收方：单聊为对方用户 ID，群聊和聊天室分别为群组 ID 和聊天室 ID。
            to: obj.to,
            // 会话类型：单聊、群聊和聊天室分别为 `singleChat`、`groupChat` 和 `chatRoom`。
            chatType: "groupChat",
            customEvent,
            customExts,
            // 消息扩展字段，不能设置为空，即设置为 "ext:null" 这种形式会出错。
            ext: {},
        };
        // 创建一条自定义消息。
        let msg = EC.message.create(option);
        state.conn.send(msg)
            .then((res) => {
                console.log(res)
                // 消息成功发送回调。
                console.log("Success");
            })
            .catch((e) => {
                console.log(e)
                // 消息发送失败回调。
                console.log("Fail");
            });
    },
    /**
     *
     * @param state
     * @param obj
     */
    sendTextMessage(state, obj) {
        let mode = obj.mode
        let option
        let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        let displayName = userInfo.displayName
        if (mode == 0) {
            option = {
                // 设置消息类型。
                type: "txt",
                // 设置消息内容。
                msg: "",
                // 设置消息接收方的用户 ID。
                to: obj.to,
                // 设置会话类型。
                chatType: obj.sendChatType,
                ext: {
                    isVideo :obj.isVideo,
                    type: obj.type,
                    info: {
                        "text": obj.content,
                        "time":  convBJ(moment(new Date())),
                        sn: obj.sn,
                        nickname: obj.nickname,
                        avatar: obj.avatar
                    },
                    to: obj.to,
                    from: JSON.parse(sessionStorage.getItem("userInfo")).code,

                },
            }
            if(displayName){
                option.ext.displayName = displayName
            }
        } else if (mode == 1) {
            option = {
                // 设置消息类型。
                type: "txt",
                // 设置消息内容。
                msg: "",
                // 设置消息接收方的用户 ID。
                to: obj.to,
                // 设置会话类型。
                chatType: obj.sendChatType,
                ext: {
                    type: obj.type,
                    info: {
                        "text": obj.content,
                        "time":convBJ(moment(new Date())),
                        sn: obj.sn,
                        nickname: obj.nickname,
                        avatar: obj.avatar,
                        hospitalName: obj.hospitalName,
                        hospital: obj.hospital
                    },
                    to: obj.to,
                    from: JSON.parse(sessionStorage.getItem("userInfo")).code,
                    hospital: userInfo.hospitalId
                },
            }
            if(displayName){
                option.ext.displayName = displayName
            }
        }


        // 创建文本消息。
        let msg = EC.message.create(option);
        state.conn.send(msg).then((res) => {
            option.ext.to = obj.to
            // 保存图文消息
            if (mode === 0) {
                let message = {
                    groupId: obj.to,
                    msgId: res.serverMsgId,
                    msg: JSON.stringify(option.ext)
                }
                messageApi.saveHistoryMessage(message).then(res => {
                    console.log(res)
                })
            } else if (mode === 1) {
                // 保存客服消息
                let message = {
                    toUser: obj.to,
                    msgId: res.serverMsgId,
                    msg: JSON.stringify(option.ext),
                    fromUser: userInfo.code,
                    hospitalId: userInfo.hospitalId
                }
                messageApi.savePersonalChatHistory(message).then(res => {
                    console.log(res)
                })
            }
            option.ext.msgId = res.serverMsgId
            option.ext.fromUserInfo = {
                nickname: "",
                avatar: ""
            }
            let cacheMessage
            if (mode === 0) {
                cacheMessage = {
                    type: 0,
                    id: obj.to,
                    chat: [
                        option.ext
                    ]
                }
            } else if (mode === 1) {
                cacheMessage = {
                    type: 1,
                    toUser: obj.to,
                    fromUser: userInfo.code,
                    id: "",
                    chat: [
                        option.ext
                    ]
                }
            }
            commit('conversion/saveMessage', cacheMessage)
            console.log("Send message success");
        }).catch((e) => {
            console.log(e)
            console.log("Send message fail");
        });
    },
}
const actions = {}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
