import {fetch, post, put} from "../http";

export const order = {
    fetchOrderList(currentPage, pageSize, data) {
        return post(`/order/records?currentPage=${currentPage}&pageSize=${pageSize}`, data);
    },
    fetchOrderDetail(orderSn) {
        return fetch(`/order/detail/${orderSn}`)
    },
    fetchOrderInquiryList(currentPage, pageSize, type, data) {
        return post(`/order/inquiry/records?currentPage=${currentPage}&pageSize=${pageSize}&type=${type}`, data)
    },
    fetchFinanceList(currentPage, pageSize, data) {
        return post(`/order/finance/records?currentPage=${currentPage}&pageSize=${pageSize}`, data)
    },
    modifyOrderStatus(sn, status) {
        return put(`/order?sn=${sn}&status=${status}`)
    },
    contactUser(sn) {
        return put(`/order/inquiry/status?sn=${sn}`)
    },
    saveCase(data) {
        return post(`/order/inquiry/case`, data)
    },
    fetchCase(orderSn) {
        return fetch(`/order/inquiry/case/${orderSn}`)
    },
    orderRefund(data) {
        return put(`/order/refund`, data)
    },
    orderVerify(sn, status) {
        return put(`/order/inquiry/verify?sn=${sn}&status=${status}`)
    },
    orderMedicalLogistics(sn, data) {
        return put(`/order/medical/logistics/${sn}`, data)
    },
    updateInquiryTime(data) {
        return put(`/order/inquiry`, data)
    },
    closeRoom(sn) {
        return put(`/order/order/room/${sn}`)
    }
}
