<template>
  <v-dialog
      v-model="$store.state.rtc.miniDialog"
      max-width="80%"

  >
    <v-card style="height: 80%" max-height="70vh">
      <v-container fluid>
        <v-row style="max-height: 80vh">
          <v-col>
            <div id="local-video" style="background-color: #E5E5E5;height: 40vh"></div>
          </v-col>
          <v-col>
            <div id="remote-video" style="background-color: #E5E5E5;height: 40vh"></div>
          </v-col>
        </v-row>
        <v-row style="height: 50px;background-color: rgba(0,0,0,0.3);align-items: center;">
          <img @click="stopRtc" style="height: 40px;margin: 0 auto" src="../../assets/stop_call.jpg"/>
        </v-row>
      </v-container>

    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RtcDialog",
  data() {
    return {
      dialog: true
    }
  },
  mounted() {
    this.zegoInit()
  },
  methods: {
    zegoInit() {
      let data = {
        success: () => this.zegoInitSuccess()
      }
      this.$store.dispatch("rtc/zegoInit", data)
    },
    zegoLogin() {
      this.$server.message.getZegoToken().then(res => {
        console.log(res)
        let payload = {
          token: res.data,
          roomId: this.$store.state.rtc.currentRoomId,
          success: (res) => this.zegoLoginSuccess(res)
        }
        this.$store.dispatch("rtc/zegoLogin", payload)
      })
    },
    zegoInitSuccess() {
      console.log("zego init success")
      this.zegoLogin()
    },
    zegoLoginSuccess(res) {
      if (res) {
        this.$store.state.rtc.zg.createStream().then((localStream) => {
          let streamID = JSON.parse(sessionStorage.getItem('userInfo')).code + 'doctor'
          // 创建媒体流播放组件对象，用于预览本地流
          const localView = this.$store.state.rtc.zg.createLocalStreamView(localStream);
          // 将播放组件挂载到页面，"local-video" 为组件容器 DOM 元素的 id 。
          localView.play("local-video");
          // 开始推流，将自己的音视频流推送到 ZEGO 音视频云
          this.$store.state.rtc.zg.startPublishingStream(streamID, localStream)
          this.$store.state.rtc.isCalling = true
        });
      }
      console.log("zego login success")
    },
    stopRtc() {
      this.$confirm('结束视频后，此单问诊无法再使用视频功能，是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$server.order.closeRoom(this.$store.state.rtc.currentRoomId).then(res => {
          console.log(res)
          this.$store.dispatch("rtc/zegoLogout")
          setTimeout(() => location.reload(), 3000)
        })
      }).catch(() => {
      });

    }
  }
}
</script>

<style scoped>

</style>
