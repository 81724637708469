import {ZegoExpressEngine} from "zego-express-engine-webrtc";
import {commit} from "vuex-pathify";

const state = {
    appId: 447409324,
    server: "wss://webliveroom447409324-api.imzego.com/ws",
    zg: undefined,
    currentRoomId: "",
    isCalling: false,
    miniDialog: true

}

const mutations = {
    setZg(state, zg) {
        state.zg = zg
    }
}
const actions = {
    zegoInit(context, payload) {
        state.zg = new ZegoExpressEngine(state.appId, state.server)
        state.zg.setDebugVerbose(false)
        state.zg.setLogConfig({
            logLevel: 'disable'
        })

        state.zg.on('roomStateChanged', (roomID, reason, errorCode, extendData) => {
            if (reason === 'LOGINING') {
                console.log('登录中')
                // 登录中
            } else if (reason === 'LOGINED') {
                console.log('loginded', roomID, reason, errorCode, extendData)
            } else if (reason === 'LOGIN_FAILED') {
                console.log('LOGIN_FAILED', roomID, reason, errorCode, extendData)
                // 登录失败
            } else if (reason === 'RECONNECTING') {
                console.log('RECONNECTING', roomID, reason, errorCode, extendData)
                // 重连中
            } else if (reason === 'RECONNECTED') {
                console.log('RECONNECTED', roomID, reason, errorCode, extendData)
                // 重连成功
            } else if (reason === 'RECONNECT_FAILED') {
                console.log('RECONNECT_FAILED', roomID, reason, errorCode, extendData)
                // 重连失败
            } else if (reason === 'KICKOUT') {
                state.currentRoomId = ''
                state.isCalling = false
                commit('rtc/zegoLogout')
                location.reload();
                console.log('KICKOUT', roomID, reason, errorCode, extendData)
                // 被踢出房间
            } else if (reason === 'LOGOUT') {
                console.log('LOGOUT', roomID, reason, errorCode, extendData)
                // 登出成功
            } else if (reason === 'LOGOUT_FAILED') {
                console.log('LOGOUT_FAILED', roomID, reason, errorCode, extendData)
                // 登出失败
            }
            // console.log('errCode', errorCode)
            // console.log('extendData', extendData)
        });
        state.zg.on('roomUserUpdate', (roomID, updateType, userList) => {
            if (updateType == 'ADD') {
                for (let i = 0; i < userList.length; i++) {
                    console.log(userList[i]['userID'], '加入了房间：', roomID)
                }
            } else if (updateType == 'DELETE') {
                for (let i = 0; i < userList.length; i++) {
                    console.log(userList[i]['userID'], '退出了房间：', roomID)
                }
            }
        });


        payload.success()

    },
    zegoLogin(context, payload) {
        let userID = JSON.parse(sessionStorage.getItem('userInfo')).code
        // let userName = "user";
        let roomID = payload.roomId;
        state.currentRoomId = roomID
        // 流状态更新回调
        state.zg.on('roomStreamUpdate', (roomID, updateType, streamList,) => {
            console.log('roomStreamUpdate', roomID, updateType, streamList)
            // 当 updateType 为 ADD 时，代表有音视频流新增，此时可以调用 startPlayingStream 接口拉取播放该音视频流
            if (updateType == 'ADD') {
                // 流新增，开始拉流
                // 这里为了使示例代码更加简洁，我们只拉取新增的音视频流列表中第的第一条流，在实际的业务中，建议开发者循环遍历 streamList ，拉取每一条音视频流
                const streamID = streamList[0].streamID;
                // streamList 中有对应流的 streamID
                state.zg.startPlayingStream(streamID).then(res => {
                    const remoteView = state.zg.createRemoteStreamView(res);
                    remoteView.play("remote-video");

                })

                // 创建媒体流播放组件对象，用于播放远端媒体流 。

                // 将播放组件挂载到页面，"remote-video" 为组件容器 <div> 元素的 id 。

            }
            // else if (updateType == 'DELETE') {
            // }

        });
        // 为避免错过任何通知，您需要在登录房间前先监听用户加入/退出房间、房间连接状态变更、推流状态变更等回调。
        state.zg.loginRoom(roomID, payload.token, {userID, userName: userID}, {userUpdate: true}).then(result => {
            console.log('loginRoom')
            payload.success(result)
        });
    },
    zegoLogout(state) {
        // state.zg.logoutRoom(state.rtc.currentRoomId)
        state.currentRoomId = ""
        state.isCalling = false
        state.miniDialog = true
        // location.reload();
    },
}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
