<template>
  <div id="app-parent">
    <!--    <div v-if="!this.$store.state.rtc.miniDialog" class="rtcDialogMini" @click="switchDialog">-->

    <!--    </div>-->
    <div
        v-if="!this.$store.state.rtc.miniDialog"
        v-drag
        style="width: fit-content; display: flex; z-index: 7;  position: absolute; top: 20%;right: 20px;align-items: center;background-color: #FF8D70;  border-radius: 15px;">
<!--      <div  style="height: 90px;width: 220px;display: flex;align-items: center;color: white;padding-left: 20px">-->
<!--        此次视频问诊时长已用完，您可与用户继续使用图文聊天功能直到您手动结束此病例。-->
<!--      </div>-->
      <div class="rtcDialogMini" @click="switchDialog">
      </div>
    </div>

    <div v-if="$store.state.rtc.isCalling">
      <RtcDialog></RtcDialog>

    </div>
    <v-app>

      <v-fade-transition mode="out-in">
        <router-view/>
      </v-fade-transition>
    </v-app>
  </div>

</template>

<script>
import '@/styles/overrides.sass'
import RtcDialog from "./components/dialog/RtcDialog";

export default {
  name: 'App',
  components: {
    RtcDialog
  },
  data: () => ({
    page: {
      currentPage: 1,
      pageSize: 20,
      totalPage: 0
    },
    //
  }),
  mounted() {
    if (sessionStorage.getItem('userInfo')) {
      this.imInit()
    }
    // this.$store.commit("imInit")
  },
  created(){
    if(window.Notification){
      if(Notification.permission == "granted"){
        console.log("允许通知");
      }
      else{
        console.log("需要通知权限");
        Notification.requestPermission((permission)=>{Notification.permission = permission;});
      }
    }
    else {console.log("浏览器不支持");}
  },
  methods: {
    imInit() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"))

      let obj = {
        username: userInfo.code,
        // pwd:userInfo.password
        pwd: 123456
      }
      this.$store.commit("im/imInit", obj)
      this.timer = setInterval(() => {
        if(document.hidden) {
          this.regularCheckInquiresAndNotify()
          this.regularCheckMOnlineOrdersAndNotify()
        }
      }, 3000)
    },
    switchDialog() {
      this.$store.state.rtc.miniDialog = !this.$store.state.rtc.miniDialog
    },
    regularCheckInquiresAndNotify(){
      let data = {
        type: 1
      }
      this.$server.order.fetchOrderInquiryList(this.page.currentPage, this.page.pageSize, 1, data).then(res => {
        if(res.data.content.length>0) {
          var result = res.data.content.filter(x => {
            return (x.inquiryType ===1 && x.orderStatus === 111)||(x.inquiryType ===2 && x.orderStatus === 102)
          })
          let num = result.length
          if (num > 0) {
            this.notify(num,`您有${num}个新预约`,'inquiries')
          }
        }
      })
    },
    regularCheckMOnlineOrdersAndNotify(){
      let data = {
        orderType: 1
      }
      this.$server.order.fetchOrderList(this.page.currentPage, this.page.pageSize, data).then(res => {
        if(res.data.content.length>0) {
          var result = res.data.content.filter(x => {
            return x.orderStatus === 202 || x.orderStatus === 211
          })
          let num = result.length
          if (num > 0) {
            this.notify(num,`您有${num}个新商城订单`,'orders')
          }
        }
      })
    },
    notify(num,content,tag){
      if (!window.Notification) {
        console.log('浏览器不支持通知');
      } else {
        // 检查用户曾经是否同意接受通知
        if (Notification.permission === 'granted') {
          var notification = new Notification("宠小书新消息", {tag:tag,body:content,renotify:true});
          notification.onclick = function(){
            window.parent.focus();
            notification.close();
          }
        } else if (Notification.permission === 'default') {
          // 用户还未选择，可以询问用户是否同意发送通知
          Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
              console.log('用户同意授权');
              var notification = new Notification("宠小书新消息", {tag:tag,body:content,renotify:true}); // 显示通知
              notification.onclick = function(){
                window.parent.focus();
                notification.close();
              }
            } else if (permission === 'default') {
              console.warn('用户关闭授权 未刷新页面之前 可以再次请求授权');
            } else {
              // denied
              console.log('用户拒绝授权 不能显示通知');
            }
          });
        } else {
          // denied 用户拒绝
          console.log('用户曾经拒绝显示通知');
        }
      }
    },
  },
  directives: {
    drag(el) {
      let oDiv = el // 当前元素
      // let self = this // 上下文
      // 禁止选择网页上的文字
      document.onselectstart = function () {
        return false
      }
      oDiv.onmousedown = function (e) {
        // 鼠标按下，计算当前元素距离可视区的距离
        let disX = e.clientX - oDiv.offsetLeft
        let disY = e.clientY - oDiv.offsetTop

        document.onmousemove = function (e) {
          // 通过事件委托，计算移动的距离
          let l = e.clientX - disX

          let t = e.clientY - disY

          if(t < e.clientY * 0.3  ){
            return
          }
          if(t>e.clientY -20){
            return
          }

          if(l <e.clientX *0.3){
            return
          }
          if(l >e.clientX ){
            return
          }
          // 移动当前元素
          oDiv.style.left = l + 'px'
          oDiv.style.top = t + 'px'
        }
        document.onmouseup = function () {
          document.onmousemove = null
          document.onmouseup = null
        }
        // return false不加的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
        return false
      }
    }
  }
};
</script>
<style>
.rtcDialogMini {
  background-image: url("https://cloudtest.yuhoulianghao.com/image/2204f07b74e444d3830db91b0a3da846.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 90px;
  height: 90px;
  text-align: center;
}
</style>
