<template>
  <div style="padding-left: 15px;padding-right: 15px;color: #000000">
    <div v-if="type === 'normal'" style=" overflow-y: scroll;height: 100%">
      <span class="title text-h4 font-weight-bold">
        {{title}}
      </span>
      <div class="title" style="height: fit-content;padding: 30px">
        <slot></slot>
      </div>
    </div>

    <div v-if="type === 'customer_title'" style=" overflow-y: scroll;height: 100%">
      <div class="title" style="height: fit-content;">
        <slot name="title_slot"></slot>
      </div>
      <div class="title"  style="height: 75%;padding: 30px;align-items: flex-start;overflow: scroll">
        <slot></slot>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  name: "PageBody",
  props:{
    title:{
      type:String,
      default:"标题"
    },
    type:{
      type:String,
      default: "normal"
    }
  }
}
</script>

<style scoped>
.title {
  width: 100%;
  height: 64px;
  opacity: 1;
  border-radius: 16px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  margin-top: 30px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
</style>
