<template>
  <div>
    <v-dialog
        v-model="show"
        width="80%"
    >

      <v-card>
        <v-card-title>
          请选择需要退款的项目
        </v-card-title>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="tableData"
              :items-per-page="999"
              hide-default-footer
          >

            <template v-slot:item.num="{ item,index }">
              <v-row style="padding: 0" justify="center">
                <v-icon color="primary" @click="minuNum(index)">mdi-minus-circle</v-icon>
                &nbsp; {{ refundSku.refundItems[index].num }} / {{ item.num }} &nbsp;
                <v-icon color="primary" @click="plusNum(index)">mdi-plus-circle</v-icon>
              </v-row>
            </template>
            <template v-slot:item.action="{index }">

              <v-row style="padding: 0" justify="center">
                <v-checkbox
                    v-model="refundSku.refundItems[index].reStore"
                    label=""
                ></v-checkbox>
              </v-row>
            </template>
            <template v-slot:item.price="{index }">

              <v-row style="padding: 0" justify="center">
                <div>
                  {{ refundSku.refundItems[index].price }} ¥
                </div>
              </v-row>
            </template>

          </v-data-table>
        </v-card-text>
        <div style="text-align: right;margin-right: 80px;margin-bottom: 10px">退款总金额{{ total }}¥</div>


        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="cancleBtn"
          >
            取消
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="orderRefund"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "RefundDialog",
  props: {
    content: {
      type: String,
      default: "确定要删除吗？"
    }
  },
  data() {
    return {
      show: false,
      orderDetail: undefined,
      headers: [
        {
          text: '商品',
          align: 'center',
          sortable: false,
          value: 'goodsName',
        },
        {
          text: '退款商品重新入库',
          align: 'center',
          sortable: false,
          value: 'action',
        },
        {
          text: '退款数量',
          align: 'center',
          sortable: false,
          value: 'num',
        }, {
          text: '退款金额',
          align: 'center',
          sortable: false,
          value: 'price',
        },
      ],
      tableData: [],

      refundSku: {
        refundDesc: "",
        refundItems: [
          {
            "itemSn": "",
            "num": 0,
            "reStore": true
          }
        ],
        remark: "",
        sn: ""
      },
      total: 0
    }
  },
  methods: {
    showDialog(orderDetail) {
      this.show = true
      console.log(orderDetail)
      this.tableData = orderDetail.items

      let itemList = []
      orderDetail.items.map(x => {
        itemList.push({
          itemSn: x.itemSn,
          num: 0,
          reStore: false,
          price: 0
        })
      })
      this.refundSku = {
        refundItems: itemList,
        sn: orderDetail.sn
      }
    },
    confirm() {
      this.show = false
      this.total = 0
      this.$emit("confirm")
    },
    minuNum(index) {
      debugger
      if (this.refundSku.refundItems[index].num <= 0) {
        return
      }
      this.refundSku.refundItems[index].num--
      this.refundSku.refundItems[index].price = this.refundSku.refundItems[index].num * this.tableData[index].sku.price / 100

      let total = 0
      this.refundSku.refundItems.map(x => total += x.price)
      this.total = total

    },
    plusNum(index) {
      if (this.refundSku.refundItems[index].num == this.tableData[index].num) {
        return
      }
      this.refundSku.refundItems[index].num++
      this.refundSku.refundItems[index].price = this.refundSku.refundItems[index].num * this.tableData[index].sku.price / 100
      let total = 0
      this.refundSku.refundItems.map(x => total += x.price)
      this.total = total
    },
    orderRefund() {
     let arr =  this.refundSku.refundItems.filter(x => x.num > 0)

      if (arr.length > 0) {
        this.$alert(`退款前请先与用户联系。退款金额为¥${this.total}，将原路返回用户账户，每个订单退款只能操作一次，是否继续？`, {
          confirmButtonText: '确定',
          callback: () => {
            this.$server.order.orderRefund(this.refundSku).then(res => {
              if (res.code == 0) {
                this.$message.success("退款成功")
                this.show = false
              }
            })
          }
        });
      } else {
        this.$message.error('请选择退款项目')
        this.confirm()
      }
    },
    cancleBtn(){
      this.total = 0
      this.show = false
    }
  }
}
</script>

<style scoped>

</style>
