<template>
  <div>
    <v-dialog
        v-model="show"
        width="580"
        persistent
    >

      <v-card>
        <v-card-title>
          {{ content }}
        </v-card-title>
        <v-card-text>
          <v-container style="margin-top: 15px">
            <v-form lazy-validation ref="form">
              <v-row>
                <v-text-field label="姓名" placeholder="请输入姓名" :rules="nameRules"
                              v-model="formData.nickname"></v-text-field>
              </v-row>
              <v-row style="margin-top: 20px">
                <v-text-field label="手机号" placeholder="请输入手机号" :rules="phoneRules"
                              v-model="formData.phone"></v-text-field>
              </v-row>
              <v-row>
                <v-text-field label="账户名" placeholder="请输入账户名" :rules="usernameRules"
                              v-model="formData.username"></v-text-field>
              </v-row>
              <v-row>
                <v-text-field label="密码" placeholder="请输入密码" type="password" :rules="password"
                              v-model="formData.password"></v-text-field>
              </v-row>
              <v-row style="margin-top: 20px">
                <v-text-field label="重新输入密码" placeholder="请再次输入密码" type="password" :rules="repPassword"
                              v-model="formData.repPassword"></v-text-field>
              </v-row>
              <v-row>
                <div style="height: 100%">
                  <v-subheader style="margin-top: 8px">请选择角色</v-subheader>
                </div>
                <div>
                  <v-checkbox
                      v-model="formData.roles"
                      label="前台"
                      value="4"
                      :rules="[v => !!v || 'Item is required']"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="formData.roles"
                      label="医生"
                      value="3"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="formData.roles"
                      label="运营"
                      value="5"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="formData.roles"
                      label="财务"
                      value="6"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="formData.roles"
                      label="库管"
                      value="7"
                  ></v-checkbox>
                  <v-checkbox
                      v-model="formData.roles"
                      label="人事"
                      value="8"
                  ></v-checkbox>
                </div>

              </v-row>
              <v-row style="margin-top: 20px">
                <v-text-field label="验证码" placeholder="请输入验证码" :rules="codeRules"
                              v-model="formData.smsCode"></v-text-field>
                <v-btn :disabled="second !== 120" color="primary" @click="getVCode">{{second === 120 ? '点击获取验证码' :second }} </v-btn>
              </v-row>

            </v-form>
          </v-container>

        </v-card-text>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="close()"
          >
            取消
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="confirm()"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "CreateAccountDialog",
  props: {
    content: {
      type: String,
      default: "确定要删除吗？"
    }
  },
  data() {
    return {
      timer:undefined,
      second:120,
      show: false,
      formData: {
        nickname: "",
        roles: [],
        password: "",
        repPassword: "",
        phone: "",
        username: ""
      },
      nameRules: [
        v => !!v || '请输入姓名',
      ],
      usernameRules: [
        v => !!v || '请输入姓名',
      ],
      phoneRules: [

        v => !!v || '请输入手机号',
        v => !! /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(v) || '请输入正确的手机号',
      ],
      password: [
        v => !!v || '请输入密码',
        v => (/^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[`~!@#$%^&*()_\-+=<>?:"{}|,.\\;'[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、/]).*$/.test(v)) || '密码最少6位，包括至少1个大写字母，1个小写字母，1个数字，1个特殊字符'
      ],
      codeRules: [
        v => !!v || '请输入验证码',
      ],
      repPassword: [
        v => !!v || '请再次输入密码',
        v => !(v !== this.formData.password) || '两次密码输入不一致',
      ]
    }
  },
  methods: {
    showDialog() {

      this.show = true
      this.$refs.form.reset()
    },
    confirm() {
      if (this.$refs.form.validate()) {
        if (this.formData.roles.length > 0) {
          this.$server.sys.createAccount(this.formData).then(res => {
            if(res.code === 0){
              this.$message.success("账号创建成功")
              this.show = false
              this.formData={
                nickname: "",
                roles: [],
                password: "",
                repPassword: "",
                phone: "",
                username: ""
              }
              this.second = 120
              this.$emit("confirm")
              this.$refs.form.reset();
              clearInterval(this.timer)
              this.timer = undefined
              // this.show = false
              this.$emit("confirm")
            }else if(res.code == 1108) {
              this.$message.error("系统中已存在该用户名，请修改")
            }
          })
        } else {
          this.$message.error("请选择角色")
        }
      }

    },
    getVCode(){
      if(this.formData.phone == ''){
        this.$message.error('请输入手机号')
        return
      }
      if(!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.formData.phone)){
        this.$message.error('请输入正确的手机号')
        return
      }
      if(!this.timer){
        this.$server.sys.sendSms(this.formData.phone).then(res=>{
          console.log(res)
          if(res.code == 0){
            this.$message.success("验证码发送成功")
            this.timer = setInterval(()=>{
              this.second --
              if(this.second === 0){
                clearInterval(this.timer)
                this.timer =undefined
                this.second = 120
              }
            },1000)
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    close(){
      this.show = false
      this.formData={
        nickname: "",
        roles: [],
        password: "",
        repPassword: "",
        phone: "",
        username: ""
      }
      this.second = 120
      clearInterval(this.timer)
      this.timer = undefined
    }

  }
}
</script>

<style scoped>

</style>
