export const orderStatusMapper = (status) => {
    switch (status) {
        case 101:
            return "待付款";
        case 102:
            return "待接单";
        case 111:
            return "已接单";
        case 112:
            return "诊断中";
        case 113:
            return "已完成";
        case 131:
            return "退款中";
        case 132:
            return "已退款";
        case 133:
            return "退款失败";
        case 141:
            return "已取消";
        case 151:
            return "已评价";
        case 201:
            return "待付款";
        case 202:
            return "待审核";
        case 211:
            return "待发货";
        case 212:
            return "已发货";
        case 213:
            return "已收货";
        case 214:
            return "已完成";
        case 231:
            return "退款中";
        case 232:
            return "已退款/部分退款";
        case 233:
            return "退款失败";
        case 241:
            return "已取消";
        case 251:
            return "已评价";
        case 300:
            return "已结束/已打款";
        default:
            return "未知状态"
    }
}


export const orderTypeMapper = (type) => {
    switch (type) {
        case 1:
            return "药房订单"
        case 2:
            return "图文问诊"
        default:
            return "未知状态"
    }
}
