<template>
  <div>
    <PageBody title="员工列表">
      <div style="width: 100%">
        <v-data-table
            :headers="headers"
            :items="tableData"
            :items-per-page="20"
            calculate-widths
            hide-default-footer
            style="width: 100%"
        >
          <template v-slot:top>
            <v-btn color="primary" @click="createAccount">添加员工</v-btn>
          </template>
          <template v-slot:header.roleStr>
            <div @click="openRoleIntro" style="display: flex;align-items: center;width: 100%;justify-content: center">
              <div>角色</div>
              <v-img style="margin-left: 10px" max-width="16" src="../assets/icon/question.png"/>
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <a @click="editRole(item)" style="color: rgba(125, 172, 232, 1);text-decoration: underline">编辑角色</a>
            <a @click="rePwd(item)" style="color: rgba(125, 172, 232, 1);text-decoration: underline;margin-left: 10px;">重置密码</a>
            <a @click="stuffQuitDialog(item)"
               style="color: rgba(125, 172, 232, 1);margin-left: 10px;text-decoration: underline">员工离职</a>
          </template>
          <template v-slot:item.roleStr="{ item }">
            <div>{{ item.roleStr }}</div>
          </template>

        </v-data-table>
        <v-pagination
            v-model="page.currentPage"
            :length="page.totalPage"
            :total-visible="10"
            circle
            @input="onPageChange"
        >
        </v-pagination>
      </div>
    </PageBody>
    <DialogComponents ref="confirmDialog" @confirm="stuffQuitDialogConfirm"
                      content="员工离职会解除员工宠小书账号和医院的绑定，撤销操作需要重新添加员工，是否继续？"></DialogComponents>
    <RoleIntroDialog ref="roleIntroDialog"></RoleIntroDialog>
    <EditRoleDialog @confirm="fetchAccountList" ref="EditRoleDialog"></EditRoleDialog>
    <CreateAccountDialog content="添加员工" ref="CreateAccountDialog" @confirm="createAccountConfirm"></CreateAccountDialog>
  </div>
</template>

<script>
import PageBody from "../components/layout/PageBody";
import DialogComponents from "../components/dialog/DialogComponents";
import RoleIntroDialog from "../components/dialog/RoleIntroDiaglog";
import EditRoleDialog from "../components/dialog/EditRoleDialog";
import CreateAccountDialog from "../components/dialog/CreateAccountDialog";

export default {
  name: "AccountMgsView",
  components: {CreateAccountDialog, EditRoleDialog, RoleIntroDialog, DialogComponents, PageBody},
  data() {
    return {
      page: {
        currentPage: 1,
        pageSize: 20,
        totalPage: 0
      },
      headers: [
        {
          text: '姓名',
          align: 'center',
          sortable: false,
          value: 'nickname',
        }, {
          text: '用户名',
          align: 'center',
          sortable: false,
          value: 'username',
        },
        {text: '手机号', value: 'phone', align: 'center',},
        {text: '角色', align: 'center', value: 'roleStr', sortable: false},
        {text: '操作', value: 'action', align: 'center',},
      ],
      tableData: [],
      showDialog: false
    }
  },
  mounted() {
    this.fetchAccountList()
  },
  methods: {
    editRole(item) {
      this.$refs.EditRoleDialog.showDialog(item)
      console.log(item)
    },
    stuffQuitDialog(item) {
      this.$confirm('员工离职会解除员工宠小书账号和医院的绑定，撤销操作需要重新添加员工，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$server.sys.deleteUser(item.id).then(res => {
          if (res.code === 0) {
            this.page = {
              currentPage: 1,
              pageSize: 20,
              totalPage: 0
            }
            this.fetchAccountList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            });
          }
        })
      }).catch(() => {

      });
      console.log(item)
    },
    stuffQuitDialogConfirm() {
    },
    openRoleIntro() {
      this.$refs.roleIntroDialog.showDialog()
    },
    createAccount() {
      this.$refs.CreateAccountDialog.showDialog()
    },
    fetchAccountList() {
      this.$server.sys.getAccountList(this.page.currentPage, this.page.pageSize).then(res => {
        this.tableData = res.data.content
        this.tableData.forEach(i => {
          let rolesStrArr = []
          i.roleList.forEach(x => {
            rolesStrArr.push(x.roleDesc)
          })
          i.roleStr = rolesStrArr.join(",")
        })
        this.page.totalPage = res.data.totalPages
        this.$forceUpdate()
        console.log(res)
      })
    },
    rePwd(item) {
      console.log(item)
      this.$server.sys.rePwd(item.id).then(res => {
        if (res.code == 0) {
          this.$message.success('密码重置成功 新密码为123456')
        }
      })
      console.log(item)
    },
    onPageChange(event) {
      this.fetchHospitalList()
      console.log(event)
    },
    createAccountConfirm() {
      this.page = {
        currentPage: 1,
        pageSize: 20,
        totalPage: 0
      }
      this.fetchAccountList()
    },
  }
}
</script>

<style scoped>

</style>
