<template>
  <v-container fluid style="height: 100%">
    <div style="height: 100%;padding: 0 15px">
      <v-row justify="start" style="height: 100%">
        <v-col style="padding-bottom: 0;padding-left: 0;padding-right: 0;height: 100%" cols="3">
          <div class="leftBar">
            <v-list style="border-radius: 16px"
            >
              <template v-for="(item,index) in listItems">
                <v-list-group
                    v-if="permission(item)"
                    :key="item.title"
                    v-model="item.active"
                    :append-icon=" item.isLeaf? null: 'mdi-chevron-up'"
                    style="border-bottom: #E5E5E5 1px solid"
                    @click="clickList(index)"
                >
                  <template v-slot:activator>
                    <div
                        style="display: flex;align-items: center;height: 56px;justify-content: space-between;width: 100%">
                      <div style="display: flex;align-items: center">
                        <img style="height: 24px;width: 24px"
                             :src="require(`../../assets/chat-left-bar-icon/${item.action}.png`)"/>
                      </div>

                      <v-list-item-content style="">
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                      </v-list-item-content>
                    </div>
                  </template>

                  <template v-if="item.type == 'single'">
                    <v-list-item
                        v-for="child in $store.state.conversion.receptionChatList"
                        :key="child.title"
                        @click="clickConv(child)"
                    >
                      <v-list-item-avatar>
                        <v-img :src="child.avatar"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <div style="display: flex;justify-content:space-between;">
                          <v-list-item-title v-text="child.nickname"></v-list-item-title>
                          <div v-if="$store.state.order.count[child.id] && $store.state.order.count[child.id] !== 0"  style="min-width: 20px;height: 20px;border-radius: 20px;background-color: red;color: #fff;padding: 0 5px;display: flex;align-items: center;justify-content: center">{{$store.state.order.count[child.id] }}</div>
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <template v-if="item.type == 'group'">
                    <v-list-item
                        v-for="child in $store.state.order.inquiryOrderList.filter(x=>x.inquiryType == 1)"
                        :key="child.title"
                        @click="clickTextOrder(child)"
                    >
                      <v-list-item-avatar>
                        <v-img :src="child.user.avatar"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <div style="display: flex;justify-content: space-between">
                          <v-list-item-title v-text="child.user.nickname"></v-list-item-title>
                          <div v-if="$store.state.order.count[child.from] && $store.state.order.count[child.groupId] !== 0"  style="min-width: 20px;height: 20px;border-radius: 20px;background-color: red;color: #fff;padding: 0 5px;display: flex;align-items: center;justify-content: center">{{$store.state.order.count[child.groupId] }}</div>
                        </div>

                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-if="item.type == 'video'">
                    <v-list-item
                        v-for="child in $store.state.order.inquiryOrderList.filter(x=>x.inquiryType == 2)"
                        :key="child.title"
                        @click="clickTextOrder(child)"
                    >
                      <v-list-item-avatar>
                        <v-img :src="child.user.avatar"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <div style="display: flex;justify-content: space-between">
                          <v-list-item-title v-text="child.user.nickname"></v-list-item-title>
                          <div v-if="$store.state.order.count[child.from] && $store.state.order.count[child.groupId] !== 0"  style="min-width: 20px;height: 20px;border-radius: 20px;background-color: red;color: #fff;padding: 0 5px;display: flex;align-items: center;justify-content: center">{{$store.state.order.count[child.groupId] }}</div>
                        </div>

                      </v-list-item-content>
                    </v-list-item>
                  </template>




                </v-list-group>
              </template>

            </v-list>
          </div>
        </v-col>
        <v-col style="padding: 0;height: 100%" cols="9">
          <v-container fluid style="height: 100%">
            <router-view></router-view>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </v-container>

</template>

<script>
export default {
  name: "ImLayout",
  data() {
    let roles = JSON.parse(sessionStorage.getItem('userInfo')).roles
    return {
      roles,
      listItems: [
        {
          action: '日历',
          title: '预约列表',
          url: "/admin/diagnoseOnline/bookList",
          active: true,
          isLeaf: true,
          auth: ["ADMIN", "RECEPTION", "DOCTOR"],
        },
        {
          action: '视频电话',
          items: [],
          title: '视频问诊',
          isLeaf: false,
          auth: ["ADMIN", "DOCTOR"],
          active:true,
          type: "video",
        },
        {
          action: '消息',
          items: [{
            title: 'Breakfast & brunch',
            avatar: "http://rhq5l7m02.hd-bkt.clouddn.com/image/d7baca95fce748289f7ab68257fb3a3a.jpeg"
          },],
          title: '图文问诊',
          isLeaf: false,
          type: 'group',
          auth: ["ADMIN", "DOCTOR"],
          active:true
        },
        {
          action: 'Reception',
          items: this.$store.state.conversion.conversion.filter(x => x.type == 'hospital'),
          title: '前台咨询',
          isLeaf: false,
          type: "single",
          auth: ["RECEPTION"],
          active:true
        },

      ],
    }
  },
  mounted() {
    this.$store.commit("conversion/fetchReceptionList")

    this.$store.commit("order/fetchOrderList")
    this.$store.commit("order/fetchVideoInquiryOrderList")

    setInterval(()=>{
      this.$store.commit("order/fetchOrderList")
      this.$store.commit("order/fetchVideoInquiryOrderList")
    },30000)
  },
  methods: {
    clickList(index) {
      if (this.listItems[index].isLeaf) {
        this.$router.push(this.listItems[index].url)
      }
      console.log(index)
    },
    permission(item) {
      let rolesArr = JSON.parse(sessionStorage.getItem('userInfo')).roles
      let hasRoles = item.auth
      let sb = new Set(hasRoles);
      let intersect = rolesArr.filter(x => sb.has(x));
      return intersect.length > 0
    },
    clickConv(child) {
      console.log(child)
      this.$store.commit('order/resetCount',child.id)
      this.$router.replace({
        path: '/admin/diagnoseOnline/chat',
        query: {
          type: 2,
          // petId: child.chat[0].petId,
          username: child.nickname,
          id: child.id
        }
      })
    },
    clickTextOrder(item) {
      console.log(item)
      if (this.$store.state.rtc.isCalling){
        this.$confirm('此操作讲失去视频连接, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.commit('rtc/zegoLogout')
          this.$store.commit('order/resetCount',item.groupId)
          this.$router.replace({
            path: `/admin/diagnoseOnline/chat`,
            query: {
              type: 1,
              sn: item.sn,
              orderItemSn: item.groupId,
              date: new Date().getTime()
            },
          })
          console.log(item)
        }).catch(() => {


        });
      }
      this.$router.replace({
        path: `/admin/diagnoseOnline/chat`,
        query: {
          type: 1,
          sn: item.sn,
          orderItemSn: item.groupId,
          date: new Date().getTime()
        },
      })
      console.log(item)
    }
  }
}
</script>

<style scoped>
.leftBar {
  /*width: calc(100% + 24px);*/
  /*/!*height: 100vh;*!*/
  /*background: rgba(96, 98, 102, 1);*/
  height: 100%;
}
</style>
