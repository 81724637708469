<template>
  <PageBody title="医生管理">
    <div style="width: 100%">
      <v-data-table
          :headers="headers"
          :items="formData"
          :items-per-page="20"
          calculate-widths
          hide-default-footer
          style="width: 100%"
      >
        <template v-slot:item.action="{ item }">
          <a style="color: rgba(125, 172, 232, 1);text-decoration: underline" @click="editDoctor(item)">编辑接诊时间</a>
          <a v-if="item.status == 2" @click="modifyStatus(item,1)"
             style="color: rgba(125, 172, 232, 1);margin-left: 10px;text-decoration: underline">启用</a>
          <a v-else @click="modifyStatus(item,2)"
             style="color: rgba(125, 172, 232, 1);margin-left: 10px;text-decoration: underline">关闭</a>
        </template>

        <template v-slot:item.videoInquiry="{ item }">
          <span>{{ item.videoInquiry ? item.videoInquiry / 100 + '元/次' : '暂无' }}</span>
        </template>
        <template v-slot:item.textInquiry="{ item }">
          <span>{{ item.textInquiry ? item.textInquiry / 100 + '元/次' : '暂无' }}</span>
        </template>
        <template v-slot:item.serviceCharge="{ item }">
          <span>{{ item.serviceCharge ? item.serviceCharge + '%' : '暂无' }}</span>
        </template>

      </v-data-table>
      <v-pagination
          v-model="page.currentPage"
          :length="page.totalPage"
          :total-visible="20"
          circle
          @input="onPageChange"
      >
      </v-pagination>
    </div>
    <DoctorEditDialog ref="doctorEditDialog" @complete="fetchDoctorList"></DoctorEditDialog>
  </PageBody>
</template>

<script>
import PageBody from "../components/layout/PageBody";
import DoctorEditDialog from "../components/dialog/DoctorEditDialog";

export default {
  name: "DoctorMgs",
  components: {DoctorEditDialog, PageBody},
  data() {
    return {
      page: {
        currentPage: 1,
        pageSize: 20,
        totalPage: 0
      },
      headers: [
        {
          text: '医生姓名',
          align: 'center',
          sortable: false,
          value: 'nickname',
        }, {
          text: '视频问诊诊费',
          align: 'center',
          sortable: false,
          value: 'videoInquiry',
        }, {
          text: '取消预约手续费',
          align: 'center',
          sortable: false,
          value: 'serviceCharge',
        }, {
          text: '图文问诊诊费',
          align: 'center',
          sortable: false,
          value: 'textInquiry',
        },
        {text: '操作', value: 'action', sortable: false, align: 'center',},
      ],
      formData: []
    }
  },
  mounted() {
    this.fetchDoctorList()
  },
  methods: {
    onPageChange(event) {
      console.log(event)
      this.page.currentPage = event
      this.fetchDoctorList()
    },
    fetchDoctorList() {
      let hospitalId = JSON.parse(sessionStorage.getItem("userInfo")).hospitalId
      this.$server.sys.getDoctorList(this.page.currentPage, this.page.pageSize, hospitalId).then(res => {
        this.formData = res.data.content
        this.page.totalPage = res.data.totalPages
        console.log(res)
      })
    },
    modifyStatus(item, status) {
      this.$server.sys.modifyDoctorStatus(item.id, status).then(res => {
        if (res.code == 0) {
          if (status == 1) {
            this.$message.success("医生已启用")
          } else {
            this.$message.success("医生已关闭")
          }
          this.fetchDoctorList()
        }
      })
    },
    editDoctor(item) {
      this.$refs.doctorEditDialog.showDialog(item)
    },
    saveOrder(event) {
      console.log(event)
    }
  }
}
</script>

<style scoped>

</style>
