<template>
  <div>
    <v-dialog
        v-model="show"
        width="50%"
    >
      <v-card>
      <v-card-text>
      <v-container fluid>
  <h3 style="margin-top: 30px">商品表格</h3>
    <div style="margin-top: 30px">
      <el-upload
          :action="`${baseUrl}/noauth/common/csvTable`"
          list-type="text"
          :file-list="ExcelList"
          :on-success="handleSuccess"
          :on-remove="handleRemove" >
        <i class="el-icon-plus"></i>
      </el-upload>
    </div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          text
          @click="show = false"
      >
        取消
      </v-btn>
      <v-btn
          color="primary"
          text
          @click="confirm()"
      >
        确认
      </v-btn>
    </v-card-actions>
      </v-container>
      </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {baseUrl} from "@/http/http";

export default {
  name: "ImportGoodsDialog",
  data() {
    return {
      show: false,
      goods: [],
      baseUrl,
      ExcelList: []
    }
  },
  methods: {
    showDialog() {
      this.ExcelList = []
      this.goods = []
      this.show = true
    },
    confirm() {
      this.show = false
      this.$emit("confirm", this.goods)
      this.goods = []
    },
    handleSuccess(file) {
      if(file.data==null)
      {
        this.$message.error("解析文件失败，请确认csv是为utf8编码并且药品信息是否正确。")
        this.goods = []
        return
      }
      this.goods = file.data
    },
    handleRemove() {
      this.goods = []
    }
  }
}

</script>

<style scoped>

</style>