<template>
  <div>
    <v-dialog
        v-model="show"
        width="500"

    >
      <v-card>
        <v-card-title>
        </v-card-title>
        <v-card-text>
          员工可以设置成如下的角色，以访问相应的功能，可同时设置多个角色。超级管理员由宠小书提供只有一个且不能更改，并且无法绑定到个人宠小书账号。设置成医生角色的员工会在医院的医生列表上显示。设置成前台角色的员工会收到联系医院前台功能的分配。
        </v-card-text>
        <v-card-text>
          <v-simple-table >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  角色
                </th>
                <th class="text-left">
                  可访问功能
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="item in tableData"
                  :key="item.name"
              >
                <td>{{ item.name }}</td>
                <td>{{ item.exp }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>


      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "RoleIntroDialog",
  props: {},
  data() {
    return {
      show: false,
      tableData: [
        {
          name: '超级管理员',
          exp: '所有功能',
        },
        {
          name: '前台',
          exp: '在线问诊和客服、到店结算、商店订单',
        },
        {
          name: '医生',
          exp: '在线问诊和客服',
        },
        {
          name: '运营',
          exp: '医院信息、线上套餐、商店库存',
        },
        {
          name: '财务',
          exp: '财务管理',
        },
        {
          name: '库管',
          exp: '商店库存',
        },
        {
          name: '人事',
          exp: '员工管理',
        },
      ],
    }
  },
  methods: {
    showDialog() {
      this.show = true
    },
    confirm() {
      this.show = false
      this.$emit("confirm")
    }
  }
}
</script>

<style scoped>

</style>
