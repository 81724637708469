<template>
  <v-dialog
      v-model="openTimeDialog"
      persistent
      max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5">添加问诊信息</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-radio-group v-model="openTimeForm.dateType" row>
            <v-radio
                label="每月"
                :value="1"
            ></v-radio>
            <v-radio
                label="每周"
                :value="2"
            ></v-radio>
            <v-radio
                label="每天"
                :value="3"
            ></v-radio>
          </v-radio-group>
          <el-select style="width: 100%" v-if="openTimeForm.dateType==1" v-model="openTimeForm.openingDateMonth"
                     multiple
                     placeholder="请选择日期">
            <el-option
                v-for="(item,index) in monthsSelectOptions"
                :key="index"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-select style="width: 100%" v-if="openTimeForm.dateType==2" v-model="openTimeForm.openingDateWeek" multiple
                     placeholder="请选择星期">
            <el-option
                v-for="(item,index) in weekSelectOptions"
                :key="index"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>

          <el-time-picker
              style="margin-top: 20px"
              size="medium"
              is-range
              arrow-control
              v-model="openTimeForm.timeRange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围">
          </el-time-picker>
          <div style="display: flex;align-items: center">
            <h4 style="color: red;margin-top: 20px">{{ $offsite != 8 ? '*此处按北京时间设置':'' }}</h4>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="close"
        >
          关闭
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="confirm"
        >
          保存
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'

export default {
  name: "DateTimeSelectDialog",
  data() {
    return {
      openTimeDialog: false,
      openTimeForm: {
        "dateType": 1,
        "endTime": "string",
        "hospitalId": 0,
        "openingDateMonth": [],
        "openingDateWeek": [],
        timeRange: [new Date(), new Date()],
        "startTime": "string",
      },
      weekSelectOptions: [{
        label: "星期一",
        value: "0"
      }, {
        label: "星期二",
        value: "1"
      }, {
        label: "星期三",
        value: "2"
      }, {
        label: "星期四",
        value: "3"
      }, {
        label: "星期五",
        value: "4"
      }, {
        label: "星期六",
        value: "5"
      }, {
        label: "星期日",
        value: "6"
      },
      ], monthsSelectOptions: [{
        label: " 一号",
        value: "0"
      }, {
        label: "二号",
        value: "1"
      }, {
        label: "三号",
        value: "2"
      }, {
        label: "四号",
        value: "3"
      }, {
        label: "五号",
        value: "4"
      }, {
        label: "六号",
        value: "5"
      }, {
        label: "七号",
        value: "6"
      }, {
        label: "八号",
        value: "7"
      }, {
        label: "九号",
        value: "8"
      }, {
        label: "十号",
        value: "9"
      }, {
        label: "十一号",
        value: "10"
      }, {
        label: "十二号",
        value: "11"
      }, {
        label: "十三号",
        value: "12"
      }, {
        label: "十四号",
        value: "13"
      }, {
        label: "十五号",
        value: "14"
      }, {
        label: "十六号",
        value: "15"
      }, {
        label: "十七号",
        value: "16"
      }, {
        label: "十八号",
        value: "17"
      }, {
        label: "十九号",
        value: "18"
      }, {
        label: "二十号",
        value: "19"
      }, {
        label: "二十一号",
        value: "20"
      }, {
        label: "二十二号",
        value: "21"
      }, {
        label: "二十三号",
        value: "22"
      }, {
        label: "二十四号",
        value: "23"
      }, {
        label: "二十五号",
        value: "24"
      }, {
        label: "二十六号",
        value: "25"
      }, {
        label: "二十七号",
        value: "26"
      }, {
        label: "二十八号",
        value: "27"
      }, {
        label: "二十九号",
        value: "28"
      }, {
        label: "三十号",
        value: "29"
      }, {
        label: "三十一号",
        value: "30"
      },
      ]
    }
  },
  methods: {
    open() {
      this.openTimeDialog = true
    },
    close() {
      this.openTimeDialog = false
      this.openTimeForm = {
        "dateType": 1,
        "endTime": "",
        "openingDateMonth": [],
        "openingDateWeek": [],
        timeRange: [new Date(), new Date()],
        "startTime": "",
      }
    },
    confirm() {
      if (this.openTimeForm.dateType == 1) {
        if (this.openTimeForm.openingDateMonth.length == 0) {
          this.$message.error("请选择每月问诊日期")
          return
        }
      } else if (this.openTimeForm.dateType == 2) {
        if (this.openTimeForm.openingDateWeek.length == 0) {
          this.$message.error("请选择每周问诊星期")
          return
        }
      }

      this.openTimeDialog = false
      this.openTimeForm.startTime = moment( this.openTimeForm.timeRange[0]).format('HH:mm:ss')
      this.openTimeForm.endTime = moment(this.openTimeForm.timeRange[1]).format('HH:mm:ss')
      switch (this.openTimeForm.dateType) {
        case 1:
          this.openTimeForm.inquiryDate = this.openTimeForm.openingDateMonth
          break;
        case 2:
          this.openTimeForm.inquiryDate = this.openTimeForm.openingDateWeek
      }
      this.$emit("confirm", this.openTimeForm);
      this.openTimeForm = {
        "dateType": 1,
        "endTime": "",
        "hospitalId": 0,
        "openingDateMonth": [],
        "openingDateWeek": [],
        timeRange: [new Date(), new Date()],
        "startTime": "",
      }
    }
  }
}
</script>

<style scoped>

</style>
