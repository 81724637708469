import { post,put } from "../http";

export const goods = {
    // 登录
    addGoods(data) {
        return post(`/goods`,data);
    },
    // 拉取商品列表
    fetchGoods(currentPage,pageSize,data){
        return post(`/goods/records?currentPage=${currentPage}&pageSize=${pageSize}`,data)
    },
    // 1正常 2下架 3删除
    // 修改商品状态
    modifyGoodsStatus(id,status){
        return put(`/goods/status?id=${id}&status=${status}`)
    },


}
