<template>
  <div>
    <v-dialog
        v-model="show"
        width="40%"
    >
      <v-card>
        <v-card-title>
          添加商品
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-form ref="form">
              <v-row>
                <v-col>
                  <v-select
                      :items="items"
                      label="商品类型"
                      placeholder="请选择商品类型"
                      v-model="goods.categoryId"
                      :rules="requireRules1"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field maxlength="30" counter="30" label="商品名称" placeholder="请输入商品名称"
                                v-model="goods.name" :rules="requireRules2"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field label="商品规格" maxlength="30" counter="30" placeholder="请输入商品规格"
                                v-model="goods.specification" :rules="requireRules3"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field maxlength="30" counter="30" label="销售单位" placeholder="请输入销售单位"
                                v-model="goods.goodsUnit" :rules="requireRules4"></v-text-field>
                </v-col>

              </v-row>
              <v-row v-if="goods.categoryId == 1 ||goods.categoryId == 2 ">
                <v-col>
                  <v-text-field maxlength="200" counter="200" label="药物成分" placeholder="请输入药物成分"
                                v-model="goods.ingredient" :rules="requireRules8"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field maxlength="30" counter="30" label="药物剂型" placeholder="请输入药物剂型"
                                v-model="goods.formulation" :rules="requireRules8"></v-text-field>
                </v-col>

              </v-row>

              <v-row>
                <v-col>
                  <v-text-field maxlength="30" counter="30" label="品牌" placeholder="请输入品牌"
                                v-model="goods.brand"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="单价" :rules="requireRules5" placeholder="请输入单价" type="number"
                                v-model="computePrice"></v-text-field>
                </v-col>


              </v-row>


              <v-row>

                <v-col>
                  <v-text-field min="1" label="库存" type="number" placeholder="请输入库存" v-model="goods.quantity"
                                :rules="requireRules6"
                                @input="goods.quantity &&( goods.quantity = goods.quantity.replace(/[^0-9]/g, ''))"></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field min="1" type="number" label="重量" placeholder="请输入药品规格" v-model="goods.weight"
                                :rules="requireRules7" @input="goods.weight &&( goods.weight= goods.weight.replace(/[^0-9]/g, ''))">
                    <template slot="append">克(g)</template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field maxlength="200" counter="200" label="说明" placeholder="请输入说明"
                                v-model="goods.intro"></v-text-field>
                </v-col>

              </v-row>
              <v-row>
              </v-row>
              <h3 style="margin-top: 30px">商品图片</h3>
              <div style="margin-top: 30px">
                <!--              <el-upload-->
                <!--                  :action="action"-->
                <!--                  :headers="headers"-->
                <!--                  list-type="picture-card"-->
                <!--                  :file-list="imageList"-->
                <!--                  :on-success="handleSuccess"-->
                <!--                  :on-remove="handleRemove"-->
                <!--                  :multiple="false"-->
                <!--              >-->
                <!--                <i class="el-icon-plus"></i>-->
                <!--              </el-upload>-->

                <el-upload
                    class="avatar-uploader"
                    :action="action"
                    :headers="headers"
                    :show-file-list="false"
                    :on-success="handleSuccess">
                  <img style="height: 140px;width: 140px" v-if="goods.images && goods.images.length > 0"
                       :src="goods.images[0]"
                       class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </div>
            </v-form>

          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="show = false"
          >
            取消
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="confirm()"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {baseUrl} from "../../http/http";

export default {
  name: "AddGoodsDialog",
  props: {
    content: {
      type: String,
      default: "确定要删除吗？"
    }
  },
  computed: {
    computePrice: {
      get() {
        return this.goods.price / 100
      },
      set(val) {
        this.goods.price = val * 100
      }
    },
  },
  data() {
    return {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`
      },
      action: `${baseUrl}/file/media `,
      show: false,
      goods: {
        "brand": "",
        "categoryId": 0,
        "code": "",
        "formulation": "",
        "goodsSpec": "",
        "goodsUnit": "",
        "images": [],
        "ingredient": "",
        "intro": "",
        "name": "",
        "price": 0,
        "quantity": 0,
        "specification": "",
        "weight": 0
      },
      imageList: [],
      baseUrl,
      // 1处方药 2非处方药 3保健品 4用品 5食品
      items: [{
        value: "1",
        text: '处方药'
      }, {
        value: "2",
        text: '非处方药'
      }, {
        value: "3",
        text: '保健品'
      }, {
        value: "4",
        text: '用品'
      }, {
        value: "5",
        text: '食品'
      }
      ],
      requireRules1: [
        v => !!v || '请选择药品分类',
      ],
      requireRules2: [
        v => !!v || '请输入商品名称',
      ],
      requireRules3: [
        v => !!v || '请输入商品规格',
      ],
      requireRules4: [
        v => !!v || '请输入商品销售单位',
      ],
      requireRules5: [
        v => !!v || '请输入商品单价',
        v => !(v < 0) || '商品单价不能为负数',
      ],
      requireRules6: [
        v => !(v < 0) || '库存数量不能为负数',
      ],
      requireRules7: [
        v => !(v < 0) || '重量不能为负数',
      ],
      requireRules8: [
        v => (this.goods.categoryId == 1 || this.goods.categoryId == 2) && !!v || '药品类型为处方药/非处方药时必填',
        v => !(v < 0) || '重量不能为负数',
      ],

    }
  },
  methods: {
    showDialog(action, data) {
      this.imageList = []
      if (action == 'add') {
        // this.$refs.form.reset()

        this.goods = {
          "brand": "",
          "categoryId": 0,
          "code": "",
          "formulation": "",
          "goodsSpec": "",
          "goodsUnit": "",
          "images": [],
          "ingredient": "",
          "intro": "",
          "name": "",
          "price": "",
          "quantity": "",
          "specification": "",
          "weight":0
        }
      } else {

        this.goods = Object.assign({},data)
        this.goods.categoryId = this.goods.categoryId +''
        this.goods.images ? this.imageList = this.goods.images.map(item => {
          return {
            name: item,
            url: item
          }
        }) : [];
      }
      this.show = true

    },
    confirm() {
      if (this.$refs.form.validate()) {
        this.show = false
        if (this.goods.categoryId != 1 && this.goods.categoryId != 2) {
          this.goods.ingredient = ""
          this.goods.formulation = ""
        }
        if(!this.goods.weight){
          this.goods.weight = 0
        }
        this.$emit("confirm", this.goods)
        this.goods = {
          "brand": "",
          "categoryId": 0,
          "code": "",
          "formulation": "",
          "goodsSpec": "",
          "goodsUnit": "",
          "images": [],
          "ingredient": "",
          "intro": "",
          "name": "",
          "price": 0,
          "quantity": 0,
          "specification": "",
          "weight": 0
        }
      }

    },
    handleSuccess(file) {
      // if(!this.goods.images){
      //   this.goods.images = []
      // }
      this.goods.images = []
      this.goods.images.push(file.data)
      this.$forceUpdate()
    },
    handleRemove(file, fileList) {
      let imageList = []
      fileList.forEach(x => {
        imageList.push(x.url)
      })
      this.goods.images = imageList
    }
  }
}
</script>

<style scoped>

</style>
