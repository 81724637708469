<template>
  <div class="title">
    <slot></slot>
  </div>
</template>

<script>
export default {
name: "StadiumBox"
}
</script>

<style scoped>
.title {
  width: 100%;
  height: fit-content;
  border-radius: 16px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  /*margin-top: 30px;*/
  padding:30px 30px;
  display: flex;
}
</style>
