
import {user} from "@/http/api/user";
import {hospital} from "./api/hospital";
import {admin} from "./api/admin";
import {sys} from "./api/sys";
import {goods} from "./api/goods";
import {order} from "./api/order"
import {message} from "./api/message"
import {pet} from "./api/pet"
import {noauth} from "./api/noauth"
export const server = {
	user,
	hospital,
	admin,
	sys,
	goods,
	order,
	message,
	pet,
	noauth
}
