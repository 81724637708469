<template>
  <div>
    <v-dialog
        v-model="show"
        width="500"
    >

      <v-card>
        <v-card-title >
          {{content}}
        </v-card-title>
        <v-card-text>
          <el-date-picker
              v-model="date"
              type="datetime"
              placeholder="选择日期时间">
          </el-date-picker>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="show = false"
          >
            取消
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="confirm()"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DateTimePicker",
  props:{
    content:{
      type: String,
      default: "更改问诊时间"
    }
  },
  data(){
    return {
      show:false,
      date:''
    }
  },
  methods:{
    showDialog(date){
      this.show = true
      this.date = date
    },
    confirm(){
      this.show = false
      this.$emit("confirm",this.date)
    }
  }
}
</script>

<style scoped>

</style>
