<template>
<div></div>
</template>

<script>
export default {
  name: "ExitView",
  mounted() {
    this.$store.commit('conversion/clearConversion')
    window.sessionStorage.clear();
    sessionStorage.clear()
    this.$store.commit('im/imClose')
    location.reload();
    location.reload();
  }
}
</script>

<style scoped>

</style>
