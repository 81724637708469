<template>
  <div>
    <v-dialog
        v-model="show"
        width="500"
    >
      <v-card>
        <v-card-title>
          请设置{{currentUser.username}}的角色
        </v-card-title>
        <v-card-text>
          <div>
            <v-checkbox
                v-model="roles"
                label="前台"
                value="4"
                :rules="[v => !!v || 'Item is required']"
            ></v-checkbox>
            <v-checkbox
                v-model="roles"
                label="医生"
                value="3"
            ></v-checkbox>
            <v-checkbox
                v-model="roles"
                label="运营"
                value="5"
            ></v-checkbox>
            <v-checkbox
                v-model="roles"
                label="财务"
                value="6"
            ></v-checkbox>
            <v-checkbox
                v-model="roles"
                label="库管"
                value="7"
            ></v-checkbox>
            <v-checkbox
                v-model="roles"
                label="人事"
                value="8"
            ></v-checkbox>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="show = false"
          >
            取消
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="confirm()"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "EditRoleDialog",
  props: {
    content: {
      type: String,
      default: "确定要删除吗？"
    }
  },
  data() {
    return {
      show: false,
      roles: [],
      currentUser: {}
    }
  },
  methods: {
    showDialog(item) {
      this.roles = []
      this.currentUser = {}
      this.currentUser = item
      item.roleList.forEach(item => {
        this.roles.push(item.id + "")
      })
      this.show = true
    },
    confirm() {
      let data = {
        roles: this.roles,
        userId: this.currentUser.id,
        username: this.currentUser.username
      }
      this.$server.sys.modifyRole(data).then(res => {
        if (res.code === 0) {
          this.show = false
          this.$emit("confirm")
          this.$message.success("修改用户角色成功")
        }else{
          this.$message.error(res.msg)
        }


      })
    }
  }
}
</script>

<style scoped>

</style>
