<template>
  <PageBody title="商店需处理订单">
    <div style="width: 100%">
      <v-data-table
          :headers="headers"
          :items="tableData"
          :items-per-page="20"
          calculate-widths
          hide-default-footer
          style="width: 100%"
      >
        <template v-slot:top>
          <v-row style="margin-bottom: 40px" justify="space-between">
            <el-date-picker
                v-model="datePicker"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
            <v-btn color="primary" @click="search">查询</v-btn>
          </v-row>

        </template>


        <template v-slot:item.orderStatus="{ item }">
          <div>{{ orderStatusMapper(item.orderStatus) }}</div>
        </template>

        <template v-slot:item.action="{ item }">
          <a @click="orderDetail(item)" style="color: rgba(125, 172, 232, 1);text-decoration: underline">查看</a>
        </template>


      </v-data-table>
      <v-pagination
          v-model="page.currentPage"
          :length="page.totalPage"
          :total-visible="10"
          circle
          @input="onPageChange"
      >
      </v-pagination>
    </div>
    <OrderDetailDialog ref="orderDetail"></OrderDetailDialog>
  </PageBody>
</template>

<script>
import PageBody from "../components/layout/PageBody";
import moment from 'moment'
import OrderDetailDialog from "../components/dialog/OrderDetailDialog";
import {orderStatusMapper} from "../util/orderMapper";

export default {
  name: "OrderMgs",
  components: {OrderDetailDialog, PageBody},
  data() {
    return {
      page: {
        currentPage: 1,
        pageSize: 20,
        totalPage: 0
      },
      headers: [
        {
          text: '订单号',
          align: 'center',
          sortable: false,
          value: 'sn',
        }, {
          text: '下单时间',
          align: 'center',
          sortable: false,
          value: 'createTime',
        },
        {text: '订单状态', value: 'orderStatus', align: 'center',},
        {text: '用户昵称', align: 'center', value: 'user.nickname', sortable: false},
        {text: '操作', value: 'action', align: 'center',},
      ],
      tableData: [],
      dataSelect: [],
      datePicker: []
      // {
      //   "id":2,
      //     "sn":"20221014390114",
      //     "hospitalId":6,
      //     "userId":1,
      //     "petId":1,
      //     "orderStatus":3,
      //     "goodsPrice":20000,
      //     "discountPrice":0,
      //     "remark":"不放辣",
      //     "orderType":1,
      //     "status":1,
      //     "createTime":"2022-10-14 11:15:13",
      //     "updateTime":"2022-10-14 11:30:05"
      // },
    }
  },
  mounted() {
    this.fetchOrder()
  },
  methods: {
    fetchOrder() {
      let data = {
        orderType: 1
      }
      this.$server.order.fetchOrderList(this.page.currentPage, this.page.pageSize, data).then(res => {
        this.tableData = res.data.content
        this.page.totalPage = res.data.totalPages
        res.data.content.map(x => {
          x.createTime = this.$localDateTime(x.createTime)
        })
        console.log(res)
      })
    },
    onPageChange() {
    },
    orderDetail(item) {
      this.$refs.orderDetail.showDialog(item.sn)
      console.log(item)
    },
    search() {
      this.page.currentPage = 1
      this.page.pageSize = 20
      if (this.datePicker.length != 0) {

        let data = {
          endTime: moment(this.datePicker[1]).format('YYYY-MM-DD HH:mm:ss',),
          startTime: moment(this.datePicker[0]).format('YYYY-MM-DD HH:mm:ss',),
          orderType: 1
        }
        this.page.currentPage = 1
        this.page.pageSize = 20

        this.$server.order.fetchOrderList(this.page.currentPage, this.page.pageSize, data).then(res => {
          console.log(res)
          res.data.content.map(x => {
            debugger
            x.createTime = this.$localDateTime(x.createTime)
          })

          this.tableData = res.data.content
          this.page.totalPage = res.data.totalPages
          this.datePicker = []
        })
      } else {
        this.page.currentPage = 1
        this.page.pageSize = 20
        this.fetchOrder()
      }
    },
    orderStatusMapper(val) {
      return orderStatusMapper(val)
    }
  }
}
</script>

<style scoped>

</style>
