import moment from "moment";

export const localDateTime = (time) => {
    let dateFormat = 'YYYY-MM-DD HH:mm:ss';
    time = moment(time).subtract(8, 'hours').format(dateFormat)
    let testDateUtc = moment.utc(time);
    let localDate = testDateUtc.local();
    return localDate.format(dateFormat)
}
export const convBJ = (time) => {
    // let dateFormat = 'YYYY-MM-DD HH:mm:ss';
    // return moment.utc(time).add(8, 'hours').format(dateFormat)
    let dateFormat = 'YYYY-MM-DD HH:mm:ss';
    return moment(time).utc().add(8, 'hours').format(dateFormat)
}

export const getOffsite = () => {
    return moment().utcOffset();
}
