import { post,put } from "../http";

export const admin = {
    // 登录
    addHospital(data) {
        return post(`/admin/hospital`,data);
    },
    // 重置密码
    resetPwd(id){
        return put(`/admin/pwd?id=${id}`);
    },
    // 字典获取
    fetchDict(){
        return post(`/admin/dict/records`)
    },
    // 保存字典
    saveOrUpdateDict(data){
        return post(`/admin/dict`,data)
    },
    // 财务管理列表
    fetchAdminOrderList(currentPage,pageSize,data){
        return post(`/admin/order/records?currentPage=${currentPage}&pageSize=${pageSize}`,data)
    },
    orderRemit(data){
        return put(`/admin/order/remit`,data)
    },
    adminHospital(hospitalId,status){
        return put(`/admin/hospital?hospitalId=${hospitalId}&status=${status}`)
    }

}
