// Pathify
import {make} from 'vuex-pathify'

// Data
const state = {
    drawer: true,
    drawerImage: false,
    mini: false,
    items: [
        {
            title: '员工管理',
            icon: 'mdi-account',
            to: '/admin/accountMgs',
            auth:['ADMIN','HR']
        },
        {
            title: '医院信息',
            icon: 'mdi-hospital-building',
            to: '/admin/hospitalInfo',
            auth:['ADMIN','OPERATION']
        },
        {
            title: '医生管理',
            icon: 'mdi-clipboard-outline',
            to: '/admin/doctorMgs',
            auth:['ADMIN','OPERATION']
        },
        {
            title: '在线问诊和客服',
            icon: 'mdi-phone',
            to: '/admin/diagnoseOnline',
            auth:['ADMIN','RECEPTION','DOCTOR']
        },
        // {
        //     title: '线上套餐',
        //     icon: 'mdi-chart-bubble',
        //     to: '',
        //     auth:['ADMIN','OPERATION']
        // },
        // {
        //     title: '到店结算',
        //     icon: 'mdi-storefront',
        //     to: '/maps/google/',
        //     auth:['ADMIN','RECEPTION']
        // },
        {
            title: '商店库存',
            icon: 'mdi-bell',
            to: '/admin/goodsList',
            auth:['ADMIN','OPERATION','KEEPER']
        }, {
            title: '商店订单',
            icon: 'mdi-storefront',
            to: '/admin/orderMgs',
            auth:['ADMIN','RECEPTION']
        }, {
            title: '财务管理',
            icon: 'mdi-wallet-outline',
            to: '/admin/orderList',
            auth:['ADMIN','FINANCE']
        },{
            title: '医院管理',
            icon: 'mdi-hospital-building',
            to: '/admin/adminHospitalMgs',
            auth:['SYS_ADMIN']
        },{
            title: '财务管理',
            icon: 'mdi-wallet-outline',
            to: '/admin/financeMgs',
            auth:['SYS_ADMIN']
        },{
            title: '字典管理',
            icon: 'mdi-book-open-variant',
            to: '/admin/dictionaryMgs',
            auth:['SYS_ADMIN',]
        },
    ],
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),
    init: async ({dispatch}) => {
        console.log('appinit', dispatch)
        //
    },
}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
