<template>
  <div>
    <PageBody title="医院列表">
      <div style="width: 100%">
        <v-btn color="primary" @click="addHospital">添加医院</v-btn>
        <v-data-table
            :headers="headers"
            :items="tableData"
            :items-per-page="20"
            calculate-widths
            style="width: 100%"
            hide-default-footer
        >
          <template v-slot:item.action="{ item }">
            <a @click="resetPassword(item)" style="color: rgba(125, 172, 232, 1);text-decoration: underline">重置密码</a>
            <a v-if="item.status == 1" @click="hospitalStatus(item.id,2)" style="color: rgba(125, 172, 232, 1);text-decoration: underline;margin-left: 10px">关闭医院</a>
            <a v-else @click="hospitalStatus(item.id,1)" style="color: rgba(125, 172, 232, 1);text-decoration: underline;margin-left: 10px">开启医院</a>
          </template>
        </v-data-table>
        <v-pagination
            v-model="page.currentPage"
            :length="page.totalPage"
            :total-visible="20"
            circle
            @input="onPageChange"
        >
        </v-pagination>
      </div>
    </PageBody>
    <AddHospitalDialog content="添加医院" ref="addHospitalDialog" @confirm="addSuccess"/>

  </div>
</template>

<script>
import PageBody from "../components/layout/PageBody";
import AddHospitalDialog from "../components/dialog/AddHospitalDialog";

export default {
  name: "AdminHospitalMgs",
  components: {AddHospitalDialog, PageBody},
  data() {
    return {
      page: {
        currentPage: 1,
        pageSize: 20,
        totalPage: 0
      },
      headers: [
        {
          text: '医院名称',
          align: 'center',
          sortable: false,
          value: 'name',
        }, {
          text: '管理员用户名',
          align: 'center',
          sortable: false,
          value: 'user.username',
        }, {
          text: '操作',
          align: 'center',
          sortable: false,
          value: 'action',
        },
      ],
      tableData: [],
    }
  },
  mounted() {
    this.fetchHospitalList()
  },
  methods: {
    fetchHospitalList() {
      this.$server.hospital.hospitalList(this.page.currentPage, this.page.pageSize).then(res => {
        this.tableData = res.data.content
        this.page.totalPage = res.data.totalPages
        console.log(res)
      })
    },
    addHospital() {
      this.$refs.addHospitalDialog.showDialog()
    },
    addSuccess() {
      this.page.currentPage = 1
      this.page.pageSize = 20
      this.fetchHospitalList()
    },
    hospitalStatus(hospitalId,status){

      this.$server.admin.adminHospital(hospitalId,status).then(res=>{
        if(res.code == 0){
          if(status == 2){
            this.$message.success('医院关闭成功')
          } else {
            this.$message.success('医院开启成功')
          }
          this.fetchHospitalList()
        }
      })
    },
    resetPassword(item) {
      this.$confirm('此操作将重置医院管理员密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(item)
        this.$server.admin.resetPwd(item.user.id).then(res => {
          if(res.code === 0){
            this.$message({
              type: 'success',
              message: '密码重置成功'
            });
          } else{
            this.$message({
              type: 'error',
              message: res.msg
            });
          }

        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  },
  onPageChange(event) {
    this.fetchHospitalList()
    console.log(event)
  },


}
</script>

<style scoped>

</style>
