<template>
  <div>
    <v-dialog
        v-model="show"
        width="500"
    >

      <v-card>
        <v-card-title>
          发货确认
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row align="center">
              <h5 style="margin-right: 10px">物流公司</h5>
              <el-select v-model="req.logisticsCode" filterable placeholder="请选择">
                <el-option
                    style="width: 240px"
                    v-for="item in logistics"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </v-row>
            <v-row align="center" style="margin-top: 30px">
              <h5 style="margin-right: 10px">物流单号</h5>
              <el-input style="width: 240px" v-model="req.waybillId"></el-input>
            </v-row>

          </v-container>

        </v-card-text>

        <v-divider>
        </v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="show = false"
          >
            取消
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="confirm()"
          >
            确认
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DeliverDialig",
  props: {
    content: {
      type: String,
      default: "确定要删除吗？"
    }
  },
  data() {
    return {
      show: false,
      order: "",
      logistics: [],
      req:{
        deliveryMethod:2,
        logisticsCode:"",
        logisticsName:"",
        waybillId:""
      }
    }
  },
  mounted() {
    this.fetchLogistics()
  },
  methods: {
    showDialog(order) {
      this.order = order
      this.show = true
    },
    confirm() {

      this.$server.order.orderMedicalLogistics(this.order.sn,this.req).then(res => {
        if (res.code == 0) {
          this.$message.success("发货成功")
          this.show = false
          this.$emit("confirm")
        }
      })
    },
    fetchLogistics() {
      this.$server.noauth.fetchLogistics().then(res => {
        res.data.map(x => {
          x.label = x.name
          x.value = x.code
        })
        this.logistics = res.data
      })
    }
  }
}
</script>

<style scoped>

</style>
