<template>
  <div>
    <PageBody title="预约列表">
      <div style="width: 100%">
        <v-data-table
            style="width: 100%"
            :headers="headers"
            :items="tableData"
            hide-default-footer
            :items-per-page="20"
            class="elevation-1"
            :calculateWidths="true"
        >
          <template v-slot:item.action="{ item }" style="">
            <div style="max-width: 100%;padding: 10px 0;display: flex;flex-direction: column;align-items: center">
              <template v-if="item.inquiryType == 2">
                <v-btn style="margin-bottom: 10px" v-if="item.orderStatus === 102" @click="confirmOrder(item,1)">确认预约
                </v-btn>
                <v-btn style="margin-bottom: 10px"  @click="confirmOrder(item,2)">取消预约
                </v-btn>
                <v-btn style="margin-bottom: 10px" @click="openDateTimePicker(item)">更改时间</v-btn>
<!--                <v-btn style="margin-bottom: 10px" @click="contactUser(item,2)">接诊</v-btn>-->
              </template>
              <template v-else>
                <v-btn style="margin-bottom: 10px" @click="contactUser(item,1)">接诊</v-btn>
              </template>
            </div>
          </template>
          <template v-slot:item.inquiryType="{ item }" style="">
            <div style="max-width: 100%;padding: 10px 0;display: flex;flex-direction: column;align-items: center">
              <span>{{ item.inquiryType == 1 ? '图文问诊' : '视频问诊' }}</span>
            </div>
          </template>
          <template v-slot:item.status="{ item }" style="">
            <div style="max-width: 100%;padding: 10px 0;display: flex;flex-direction: column;align-items: center">
              <span>{{ orderStatusMapper(item.orderStatus) }}</span>
            </div>
          </template>

          <template v-slot:item.subscribeTime="{ item }" style="">
            <div style="max-width: 100%;padding: 10px 0;display: flex;flex-direction: column;align-items: center">
              <span>{{
                  item.inquiryType == 1 ? $localDateTime(item.createTime)  : item.subscribeTime
                }}</span>
            </div>
          </template>


        </v-data-table>
        <div style="margin-top: 40px">
          <v-pagination
              v-model="page.currentPage"
              :length="page.totalPage"
              :total-visible="10"
              circle
              @input="onPageChange"
          >
          </v-pagination>
        </div>

      </div>
    </PageBody>
    <DateTimePicker ref="dateTimePicker" @confirm="confirm"></DateTimePicker>
  </div>
</template>

<script>
import PageBody from "../components/layout/PageBody";
import {orderStatusMapper} from '../util/orderMapper'
import DateTimePicker from "../components/dialog/DateTimePicker";
import  moment from "moment"
import {convBJ} from  "../util/timeUtils"
export default {
  name: "BookList",
  components: {DateTimePicker, PageBody},
  data() {
    return {
      orderDateTime: "",
      page: {
        currentPage: 1,
        pageSize: 20,
        totalPage: 0
      },
      headers: [
        {text: '操作', value: 'action', align: 'center', sortable: false, width:"120"},

        {
          text: '预约时间',
          align: 'center',
          sortable: false,
          value: 'subscribeTime',
          width:"120"
        }, {
          text: '问诊类型',
          align: 'center',
          sortable: false,
          value: 'inquiryType',
          width:"120"
        },
        {text: '预约医生', value: 'nickname', align: 'center', sortable: false, width:"120"},
        {text: '备注信息', align: 'center', value: 'remark', sortable: false, width:"120"},
        {text: '订单状态', align: 'center', value: 'status', sortable: false, width:"120"},

        {text: '用户名', align: 'center', value: 'user.nickname', sortable: false, width:"120"},
        {text: '手机号', align: 'center', value: 'user.phone', sortable: false, width:"120"},
        {text: '宠物名', align: 'center', value: 'pet.name', sortable: false, width:"120"},
        {text: '宠物品种', align: 'center', value: 'pet.typeName', sortable: false, width:"120"},
        {text: '宠物年龄', align: 'center', value: 'pet.birthday', sortable: false, width:"120"},
      ],
      tableData: [],
      currentSn: ""
    }
  },
  mounted() {
    this.fetchOrderList()
  },
  methods: {
    fetchOrderList() {
      let data = {
        type: 1
      }
      this.$server.order.fetchOrderInquiryList(this.page.currentPage, this.page.pageSize, 1, data).then(res => {
        res.data.content.map(x => {
          x.subscribeTime = this.$localDateTime(x.subscribeTime)
        })
        this.tableData = res.data.content

        this.page.totalPage = res.data.totalPages
      })
    },
    onPageChange(event) {
      this.fetchOrderList();
      console.log(event)
    },
    orderStatusMapper(val) {
      return orderStatusMapper(val)
    },
    contactUser(item,type) {
      let doctorId = JSON.parse(sessionStorage.getItem('userInfo')).id
      if(doctorId != item.sysUserId){
        this.$message.error("请使用预约的医生账号登陆后接诊")
        return
      }

      if(type == 1){
        let roles = JSON.parse(sessionStorage.getItem("userInfo")).roles
        console.log(roles)
        if (roles.length == 1 && roles[0] == 'RECEPTION') {
          this.$message.error("请使用预约的医生账号登陆后接诊")
          return
        } else {
          this.$server.order.contactUser(item.sn).then(res => {
            if(res.msg == "未登录或权限不足"){
              this.$message.error("请使用预约的医生账号登陆后接诊")
            } else {
              let groupId = res.data.items[0].groupId
              this.$router.push({
                path: 'chat',
                query: {
                  type: 1,
                  sn: item.sn,
                  orderItemSn: groupId
                }
              })
            }

          })
        }
      } else {
        this.page = {
          currentPage: 1,
          pageSize: 20,
          totalPage: 0
        }
        this.fetchOrderList()
      }

    },
    confirmOrder(item, status) {
      console.log(item)
      this.$server.order.orderVerify(item.sn, status).then(res => {
        if (res.code == 0) {
          this.page = {
            currentPage: 1,
            pageSize: 20,
            totalPage: 0
          }
          this.fetchOrderList()
          if (status === 1) {
            this.$message("视频问诊预约成功")
          } else if (status === 2) {
            this.$message("预约已取消")
          }
        }
      })
    },
    openDateTimePicker(item) {
      this.currentSn = item.sn
      this.$refs.dateTimePicker.showDialog(item.subscribeTime)
    },
    confirm(date) {
      let data = {
        sn: this.currentSn,
        subscribeTime: convBJ(moment(date).format('YYYY-MM-DD HH:mm:ss'))
      }
      this.$server.order.updateInquiryTime(data).then(res => {
        if(res.code == 0){
          this.page = {
            currentPage: 1,
            pageSize: 20,
            totalPage: 0
          }
          this.fetchOrderList()
          this.$message.success("视频问诊时间修改成功")
        } else {
          this.$message.error("该问诊时间不可用")
        }
      })
    }
  }

}
</script>

<style scoped>

</style>
