<template>
  <PageBody title="财务管理">
    <div style="width: 100%">
      <v-data-table
          :headers="headers"
          :items="tableData"
          :items-per-page="20"
          calculate-widths
          hide-default-footer
          style="width: 100%"
      >
        <template v-slot:top>
          <div style="display: flex;justify-content:space-between;">
            <div style="margin-bottom: 40px">
              <el-date-picker
                  v-model="dataSelect"
                  type="datetimerange"
                  align="right"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '00:00:00']">
              </el-date-picker>
            </div>
            <v-btn color="primary" @click="search">查询</v-btn>
          </div>


        </template>

        <template v-slot:item.updatePrice="{ item }">
          <div>{{ item.updatePrice / 100 }}¥</div>
        </template>
        <template v-slot:item.orderStatus="{ item }">
          <div>{{ orderStatusMapper(item.orderStatus) }}</div>
        </template>
        <template v-slot:item.orderType="{ item }">

          <div>{{ item.orderType == 1 ? '商品订单' : item.orderType == 2 ? '问诊订单' : '套餐' }}</div>
        </template>

        <template v-slot:item.action="{ item }">
          <a @click="orderDetail(item)" style="color: rgba(125, 172, 232, 1);text-decoration: underline">查看</a>
          <a
              style="color: gray;text-decoration: underline;margin-left: 10px"
              v-if="item.orderStatus == 131 || item.orderStatus == 231">退款中</a>
          <a
             style="color: gray;text-decoration: underline;margin-left: 10px"
             v-else-if="item.orderStatus == 232 || item.orderStatus == 132">已退款</a>
          <a @click="refund(item) "
             style="color: rgba(125, 172, 232, 1);text-decoration: underline;margin-left: 10px"
             v-else-if="item.orderStatus != 132">退款</a>
          <!--          <a @click="stuffQuitDialog(item)"-->
          <!--             style="color: rgba(125, 172, 232, 1);margin-left: 10px;text-decoration: underline">员工离职</a>-->
        </template>


      </v-data-table>
      <v-pagination
          v-model="page.currentPage"
          :length="page.totalPage"
          :total-visible="20"
          circle
          @input="onPageChange"
      >
      </v-pagination>
      <OrderDetailDialog ref="orderDetail"></OrderDetailDialog>
      <RefundDialog ref="refundDialog"></RefundDialog>
    </div>
  </PageBody>
</template>

<script>
import PageBody from "../components/layout/PageBody";
import {orderStatusMapper} from '../util/orderMapper'
import OrderDetailDialog from "../components/dialog/OrderDetailDialog";
import RefundDialog from "../components/dialog/RefundDialog";
import moment from "moment";

export default {
  name: "OrderList",
  components: {RefundDialog, OrderDetailDialog, PageBody},
  data() {
    return {
      page: {
        currentPage: 1,
        pageSize: 20,
        totalPage: 0
      },
      headers: [
        {
          text: '订单号',
          align: 'center',
          sortable: false,
          value: 'sn',
        }, {
          text: '下单时间',
          align: 'center',
          sortable: false,
          value: 'createTime',
        },
        {text: '费用', value: 'updatePrice', align: 'center', sortable: false,},
        {text: '订单状态', value: 'orderStatus', align: 'center', sortable: false,},
        {text: '用户昵称', align: 'center', value: 'user.nickname', sortable: false},
        {text: '订单类型', value: 'orderType', align: 'center', sortable: false,},
        {text: '操作', value: 'action', align: 'center', sortable: false,},
      ],
      tableData: [],
      dataSelect: []
    }
  },
  mounted() {
    this.fetchOrder()
  },
  methods: {
    fetchOrder() {
      let data = {}
      this.$server.order.fetchFinanceList(this.page.currentPage, this.page.pageSize, data).then(res => {
        res.data.content.map(x => {
          x.createTime = this.$localDateTime(x.createTime)
        })
        this.tableData = res.data.content
        this.page.totalPage = res.data.totalPages
      })
    },
    onPageChange(event) {
      this.page.currentPage = event
      this.fetchOrder()
    },
    orderDetail(item) {
      this.$refs.orderDetail.showDialog(item.sn)
      console.log(item)
    },
    orderStatusMapper(val) {
      return orderStatusMapper(val)
    },
    refund(item) {
      let sn = item.sn
      console.log(sn)


      this.$confirm('确定要给此订单退款吗', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$server.order.fetchOrderDetail(sn).then(res => {
          console.log(res)
          if (res.data.orderType == 2) {
            // 问诊订单
            let obj = {
              refundItems: [
                res.data.items[0]
              ],
              sn: res.data.sn
            }
            this.$server.order.orderRefund(obj).then(res => {
              if (res.code == 0) {
                this.page= {
                  currentPage: 1,
                      pageSize: 20,
                      totalPage: 0
                }
                setTimeout(()=>  this.fetchOrder(),1000)

                this.$message.success("退款成功")
                this.show = false
              } else {
                this.$message.error(res.msg)
                this.show = false
              }
            })

          } else {
            let order = res.data
            this.$refs.refundDialog.showDialog(order)
          }

        })
      }).catch(() => {

      });

    },
    search() {
      if (this.dataSelect.length != 0) {
        let data = {
          endTime: moment(this.dataSelect[1]).format('YYYY-MM-DD HH:mm:ss',),
          startTime: moment(this.dataSelect[0]).format('YYYY-MM-DD HH:mm:ss',),
        }
        this.page.currentPage = 1
        this.page.pageSize = 20

        this.$server.order.fetchFinanceList(this.page.currentPage, this.page.pageSize, data).then(res => {
          res.data.content.map(x => {
            x.createTime = this.$localDateTime(x.createTime)
          })
          this.tableData = res.data.content
          this.page.totalPage = res.data.totalPages
          this.dataSelect = []
        })
      } else {
        this.page.currentPage = 1
        this.page.pageSize = 20
        this.fetchOrder()
      }
    }

  }
}
</script>

<style scoped>

</style>
