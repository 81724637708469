import {fetch, post} from "../http";

export const message = {
    // 获取群聊历史
    fetchHistoryMessage(currentPage, pageSize, groupId) {
        return post(`/msg/records?currentPage=${currentPage}&pageSize=${pageSize}&groupId=${groupId}`);
    },
    // 保存历史消息
    saveHistoryMessage(data) {
        return post(`/msg`, data)
    },
    getZegoToken() {
        return fetch(`/msg/zego`)
    },
    // 获取前台聊天历史
    fetchPersonalChatHistory(currentPage, pageSize, user, hospitalId,toUser) {
        return post(`/msg/personal/records?currentPage=${currentPage}&pageSize=${pageSize}&user=${user}&hospitalId=${hospitalId}&toUser=${toUser}`)
    },
    // 保存前台历史消息
    savePersonalChatHistory(data){
        return post(`/msg/personal`,data)
    },
    fetchReceptionMsg(){
        return fetch(`/msg/sys/reception/user`)
    }

}
